import { useRef } from 'react';
import confetti from 'canvas-confetti';

export function useConfetti(options?: any): [React.MutableRefObject<any>, () => void] {
  const ref = useRef<any>();
  return [
    ref,
    () => {
      const mediaQuery = matchMedia('(prefers-reduced-motion: no-preference)');
      if (mediaQuery?.matches && ref.current) {
        const { top, height, left, width } = ref.current?.getBoundingClientRect() ?? {
          top: 0,
          height: 0,
          left: 0,
          width: 0,
        };
        const y = (top + height / 2) / innerHeight;
        const x = (left + width / 2) / innerWidth;
        confetti({
          origin: { x, y },
          angle: 45,
          spread: 120,
          ...options,
        });
      }
    },
  ];
}
