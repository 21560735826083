import React, { FC, useEffect } from 'react';
import { WebUxFooter, WebUxHeader } from './WebUxHeader';
import { WebUxContent } from './WebUxContent';
import { useNavigate } from 'react-router-dom';
import { useUserInfo } from '../connection/Application';
import { Grid } from '@mui/material';

export const WebUx: FC = () => {
  const user = useUserInfo();

  const history = useNavigate();
  useEffect(() => {
    if (!user) {
      // history('/login');
    }
  }, [history, user]);
  return (
    <Grid container spacing={3}>
      <Grid style={{ position: 'relative' }}>
        <WebUxHeader />
        <WebUxContent />
        <WebUxFooter />
      </Grid>
    </Grid>
  );
};
