import React, {
  forwardRef,
  ForwardRefRenderFunction,
  useCallback,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { Button, ButtonGroup } from '@chakra-ui/react';
import { FiRotateCcw } from 'react-icons/fi';
import { getCorrectAnswers, WORD_BANK } from '../../utils';
import { useConfetti } from '../../hooks/useConfetti';

interface SubmissionI {
  taskId: string;
  successMessage: string;
  failureMessage: string;
  items: any[];
  correctAnswer: string[] | undefined;
  hasSubmitted: boolean;
  isCorrect: boolean;
  initialItems: any[];
  setIsCorrect: (e: boolean) => void;
  setCorrectAnswer: (e: any) => void;
  setItems: (e: any[]) => void;
  setHasSubmitted: any;
  canswers: string[] | undefined;
  setCanswers: (e: any) => void;
}

const Submission1: ForwardRefRenderFunction<
  {
    submit: (answers: string[], dAnswers: undefined | string[]) => void;
  },
  SubmissionI
> = (
  {
    taskId,
    items,
    initialItems,
    isCorrect,
    hasSubmitted,
    setIsCorrect,
    setItems,
    setHasSubmitted,
    setCorrectAnswer,
    correctAnswer,
    failureMessage,
    successMessage,
    canswers,
    setCanswers,
  },
  ref,
) => {
  const [solutionShown, setSolutionShown] = useState(false);
  const [submitButtonRef, confetti] = useConfetti();

  useImperativeHandle(
    ref,
    () => ({
      submit(answers: string[], dAnswers: undefined | string[]) {
        checkAnswers(answers, dAnswers);
      },
    }),
    [],
  );

  const allBlanksEmpty = useMemo(
    () => !Object.entries(items).some(([key, value]) => key !== WORD_BANK && value.items.length),
    [items],
  );

  const checkAnswers = useCallback(
    (answers: string[], dAnswers: undefined | string[]) => {
      console.log(answers, dAnswers);
      let isCorrect = true;
      const checkedBlanks = Object.entries(items).reduce((acc: any, [key, value]) => {
        if (key !== WORD_BANK) {
          let isBlankCorrect = false;
          if (dAnswers) {
            isBlankCorrect = dAnswers[value.number] === answers[value.number];
            acc[key] = {
              ...value,
              items: [dAnswers[value.number]],
              isCorrect: isBlankCorrect,
            };
          } else {
            acc[key] = {
              ...value,
              isCorrect: isBlankCorrect,
            };
          }

          // if at least one blank is incorrect, the whole activity is incorrect
          // need to update FillInTheBlanksInner `isCorrect` state
          if (!isBlankCorrect) {
            isCorrect = isBlankCorrect;
          }
        } else {
          acc[key] = { ...value, isCorrect: null };
        }

        return acc;
      }, {});

      setIsCorrect(isCorrect);
      setItems(checkedBlanks);
      setHasSubmitted(true);
      setSolutionShown(false);

      // showSolution();

      if (isCorrect) {
        confetti();
      }
    },
    [items],
  );

  const reset = useCallback(() => {
    setCorrectAnswer(correctAnswer?.map(() => '') ?? []);
    setItems(initialItems);
    setIsCorrect(false);
    setHasSubmitted(false);
    setCanswers(canswers?.map(() => ''));
    setSolutionShown(false);
  }, [initialItems]);

  const showSolution = () => {
    setItems(getCorrectAnswers(items));
    setIsCorrect(true);
    setHasSubmitted(true);
    setSolutionShown(true);
    confetti();
  };

  return (
    <>
      <ButtonGroup mt='3'>
        {/* <Button*/}
        {/*  isDisabled={allBlanksEmpty || isCorrect}*/}
        {/*  onClick={checkAnswers}*/}
        {/*  ref={submitButtonRef}>*/}
        {/*  Submit*/}
        {/* </Button>*/}

        {!hasSubmitted && !allBlanksEmpty && (
          <Button rightIcon={<FiRotateCcw />} onClick={reset}>
            Reset
          </Button>
        )}
      </ButtonGroup>

      {/* {hasSubmitted && (*/}
      {/*  <Alert status={isCorrect ? 'success' : 'error'} mt='3'>*/}
      {/*    <AlertIcon />*/}
      {/*    <AlertDescription>*/}
      {/*      {isCorrect ? (*/}
      {/*        <>*/}
      {/*          <strong>{successMessage}</strong>*/}
      {/*        </>*/}
      {/*      ) : (*/}
      {/*        <>*/}
      {/*          <strong>{failureMessage}</strong>*/}
      {/*        </>*/}
      {/*      )}*/}
      {/*    </AlertDescription>*/}
      {/*  </Alert>*/}
      {/* )}*/}
    </>
  );
};

const Submission = forwardRef<
  {
    submit: (answers: string[], dAnswers: undefined | string[]) => void;
  },
  SubmissionI
>(Submission1);
export default Submission;
