import React, {
  FC,
  forwardRef,
  ForwardRefRenderFunction,
  Fragment,
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import Submission from './Submission';
import { Box, ChakraProvider, Flex, Text } from '@chakra-ui/react';
import { SolutionGetter, WORD_BANK } from '../../utils';
import shuffle from 'lodash/shuffle';
import uniq from 'lodash/uniq';
import ReactHtmlParser from 'react-html-parser';
import { CircularProgress } from '@mui/material';
import { SortableItem2 } from './SortableItem';

// https://docs.dndkit.com/introduction/getting-started

// Modified the Multiple Containers Grid example on Storybook
// https://5fc05e08a4a65d0021ae0bf2-xkdjvdfnuz.chromatic.com/?path=/docs/presets-sortable-multiple-containers--grid
// code here: https://github.com/clauderic/dnd-kit/blob/b7355d19d9e15bb1972627bb622c2487ddec82ad/stories/2%20-%20Presets/Sortable/MultipleContainers.tsx

// TODO: make custom "coordinates getter" to improve UX of keyboard navigation? -- https://docs.dndkit.com/api-documentation/sensors/keyboard

// need a JSX component that can accept props
export function Blank(solution: any) {
  return <span></span>;
}

const mod = 100000007;

const countFreq = (pat: string, txt: string) => {
  const M = pat.length;
  const N = txt.length;
  let res = 0;

  // A loop to slide pat[] one by one
  for (let i = 0; i <= N - M; i++) {
    // For current index i, check for
    // pattern match
    let j;
    for (j = 0; j < M; j++) {
      if (txt[i + j] != pat[j]) {
        break;
      }
    }

    // If pat[0...M-1] = txt[i, i+1, ...i+M-1]
    if (j == M) {
      res++;
      j = 0;
    }
  }
  return res;
};

const transform = (node: any, index: number) => {
  if (node.type === 'text' && node.data.includes('###BLANK###')) {
    return (
      <>
        <>{ReactHtmlParser(node.data.slice(0, node.data.indexOf('###BLANK###')))}</>
        <Blank solution={['1', '2', '3', '4']} />
        <>
          {ReactHtmlParser(
            node.data.slice(node.data.indexOf('###BLANK###') + 11, node.data.length),
            { transform: transform },
          )}
        </>
      </>
    );
  }
};

const textToHtml: any = (x: any, i: number, solutions: string[]) => {
  if (typeof x === 'string' && (x ?? '').includes('###BLANK###')) {
    return [
      x.slice(0, x.indexOf('###BLANK###')),
      <Blank key={i} solution={solutions} id={`blank-${i}`} />,
      ...textToHtml(x.slice(x.indexOf('###BLANK###') + 11, x.length), i + 1, solutions),
    ];
  } else {
    return [x];
  }
};

const addString = (y: any) => {
  let t = '';
  if (y?.props?.children) {
    for (let i = 0; i < y?.props?.children.length; i++) {
      if (typeof y?.props?.children[i] === 'string') {
        t += y?.props?.children[i];
      } else {
        t += addString(y?.props?.children[i]);
      }
    }
  }
  return t;
};

const simplyfy: any = (x: any, value: number, solutions: string[]) => {
  let m = value;
  if (x?.props?.children) {
    const y = [];
    for (let i = 0; i < x?.props?.children.length; i++) {
      y.push(simplyfy(x?.props?.children?.[i], m, solutions));
      m += countFreq('###BLANK###', addString(x?.props?.children?.[i]));
    }
    if (Array.isArray(y[0])) {
      return { ...x, props: { ...x.props, children: y[0] } };
    } else {
      return { ...x, props: { ...x.props, children: y } };
    }
  }
  return textToHtml(x, m, solutions);
};

const parseItemsFromChildren = (
  children: any,
  solutions: any[],
  isTaskComplete?: boolean,
  correctAnswer?: any[],
) => {
  const solutionGetter = new SolutionGetter();
  let value = 0;
  const children1 = [];
  for (let i = 0; i < children.length; i++) {
    children1.push(simplyfy(children?.[i], value, solutions));
    value += countFreq('###BLANK###', addString(children?.[i]));
  }
  const childrenWithBlanks = React.Children.toArray(children1).map((child: any, index) => {
    return child;
  });

  const blanks: any = childrenWithBlanks.reduce((acc: any, currChild) => {
    if (currChild.solutions) {
      return {
        ...acc,
        [currChild.id]: currChild,
      };
    }

    return acc;
  }, {});

  for (let i = 0; i < value; i++) {
    blanks[`blank-${i}`] = {
      id: `blank-${i}`,
      number: i,
      solutions,
      isCorrect: isTaskComplete || null,
      items: correctAnswer?.[i] ?? [],
    };
  }

  blanks[WORD_BANK] = {
    items: shuffle(uniq(solutions)),
    showingItems: shuffle(uniq(solutions)),
  };
  return [blanks, childrenWithBlanks];
};

const correctParseItemsFromChildren1 = (children: any, solutions: any[]) => {
  let value = 0;
  const children1 = [];
  for (let i = 0; i < children.length; i++) {
    children1.push(simplyfy(children?.[i], value, solutions));
    value += countFreq('###BLANK###', addString(children?.[i]));
  }
  const childrenWithBlanks = React.Children.toArray(children1).map((child: any, index) => {
    return child;
  });

  const blanks: any = childrenWithBlanks.reduce((acc: any, currChild) => {
    if (currChild.solutions) {
      return {
        ...acc,
        [currChild.id]: currChild,
      };
    }

    return acc;
  }, {});

  for (let i = 0; i < solutions.length; i++) {
    blanks[`blank-${i}`] = {
      id: `blank-${i}`,
      number: i,
      solutions,
      isCorrect: true,
      items: solutions[i],
    };
  }

  blanks[WORD_BANK] = {
    items: shuffle(uniq(solutions)),
    showingItems: shuffle(uniq(solutions)),
  };
  return [blanks, childrenWithBlanks];
};

interface FillTextI {
  taskId: string;
  answers: any[];
  correctAnswer: string[] | undefined;
  dAnswers: undefined | string[];
  setCorrectAnswer: (x: any) => void;
  children?: any;
  successMessage?: string;
  title?: string;
  failureMessage?: string;
  items?: object;
}

interface CorrectFillTextI {
  children?: any;
  answers?: any[];
}

interface CallBlankI {
  child: any;
  index: number | string;
  items: any;
  setItems: (x: any) => void;
  setCorrectAnswer: (x: any) => void;
  correctAnswer: string[] | undefined;
  isMapRemove: (x: string) => void;
  hasSubmitted?: boolean;
  disabled?: boolean;
}

const CallBlank: FC<CallBlankI> = ({
  child,
  index,
  items,
  setItems,
  setCorrectAnswer,
  correctAnswer,
  isMapRemove,
  hasSubmitted,
  disabled,
}) => {
  const [props] = useState(child.props);

  if (props?.children) {
    const x = props.children.map((child1: any, index1: number) => {
      return (
        <CallBlank
          key={index1 + '-' + index}
          child={child1}
          index={index1 + '-' + index}
          items={items}
          setCorrectAnswer={setCorrectAnswer}
          correctAnswer={correctAnswer}
          setItems={setItems}
          isMapRemove={isMapRemove}
          hasSubmitted={hasSubmitted}
          disabled={disabled}
        />
      );
    });
    return { ...child, props: { ...child.props, children: x } };
  }

  if (props?.solution && items?.[props?.id]) {
    const { items: blankItems, isCorrect: isBlankCorrect } = items[props?.id];
    return (
      <>
        <div
          key={props.id}
          id={props.id}
          style={{
            height: '40px',
          }}>
          {[blankItems].map((value: string) => {
            return (
              <SortableItem2
                isMap={items?.[props?.id].items.length > 0}
                isMapRemove={() => isMapRemove(props?.id)}
                key={`sortable-item--${value}`}
                setValue={(x) => {
                  setCorrectAnswer(
                    correctAnswer?.map((e: string, i: number) =>
                      i === items[props?.id].number ? x : e,
                    ),
                  );
                  // setItems({
                  //   ...items,
                  //   [props?.id]: { ...items[props?.id], items: [x] },
                  // });
                }}
                value={correctAnswer?.[items[props?.id].number] ?? ''}
                id={props?.id}
                taskId={'1'}
                isCorrect={isBlankCorrect}
                hasSubmitted={hasSubmitted}
                disabled={disabled}
              />
            );
          })}
        </div>
      </>
    );
  }
  return <Fragment key={index}>{child}</Fragment>;
};

const FillText1: ForwardRefRenderFunction<
  {
    submit: (answers: string[], dAnswers: undefined | string[]) => void;
    getAnswer: () => string[];
  },
  FillTextI
> = (
  {
    taskId,
    answers,
    dAnswers,
    setCorrectAnswer,
    correctAnswer,
    children,
    title = 'Drag and Drop',
    successMessage = 'Nicely done!',
    failureMessage = 'read the course to find the right information.',
  },
  ref,
) => {
  const [isCorrect, setIsCorrect] = useState(false);
  const [loading, setLoading] = useState(false);
  const [activeId, setActiveId] = useState(null);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [canswers, setCanswers] = useState(answers.map(() => ''));
  const submitRef = useRef<any>();

  useImperativeHandle(
    ref,
    () => ({
      submit(answers: string[], dxAnswers: undefined | string[]) {
        setCanswers(dxAnswers ?? []);
        submitRef.current?.submit(answers, dxAnswers);
      },
      getAnswer() {
        return canswers;
      },
    }),
    [canswers],
  );

  const [defaultItems, childrenWithBlanks] = useMemo(() => {
    return parseItemsFromChildren(children, answers, hasSubmitted, correctAnswer);
    // if (hasSubmitted) {
    //   submitRef.current.submit();
    // }
  }, [hasSubmitted]);

  // keys in `items` are the ids of the blanks/droppableContainers
  const [items, setItems] = useState<any>(defaultItems);
  const allBlanksEmpty = useMemo(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    () => !Object.entries(items).some(([key, value]) => key !== WORD_BANK && value.items.length),
    [items],
  );

  // find the blank/droppableContainer that an item is in
  const findContainer = useCallback(
    (id: string) => {
      if (id in items) {
        return id;
      }

      return Object.keys(items).find((key) => items[key].items.includes(id));
    },
    [items],
  );

  const isMapRemove = useCallback(
    (x: string) => {
      setLoading(true);
      setItems({
        ...items,
        WORD_BANK: {
          items: items?.[WORD_BANK]?.items ?? [],
          showingItems: [...(items?.[WORD_BANK]?.showingItems ?? []), ...(items?.[x]?.items ?? [])],
          isCorrect: null,
        },
        [x]: {
          ...items?.[x],
          items: [],
        },
      });
      setTimeout(() => {
        setLoading(false);
      }, 600);
    },
    [items, setItems],
  );

  const [colorScheme] = useState(!hasSubmitted ? 'blue' : isCorrect ? 'green' : 'red');

  return (
    <Box py='1' maxW='960px' sx={{ width: '100%' }}>
      <Flex align='center' color={`${colorScheme}.600`} fontWeight='semibold' mb='2'>
        <Text>{title}</Text>
      </Flex>
      <ChakraProvider>
        {!loading ? (
          <div>
            <Flex direction='column' alignItems='flex-start'>
              <div>
                {childrenWithBlanks.map((child: any, index: number) => (
                  <CallBlank
                    key={index}
                    child={child}
                    index={index}
                    items={items}
                    setItems={setItems}
                    setCorrectAnswer={setCorrectAnswer}
                    correctAnswer={correctAnswer}
                    isMapRemove={
                      !hasSubmitted
                        ? isMapRemove
                        : (x) => {
                            return;
                          }
                    }
                    hasSubmitted={hasSubmitted}
                    disabled={!!dAnswers}
                  />
                ))}
              </div>
            </Flex>
            <Submission
              ref={submitRef}
              taskId={taskId}
              isCorrect={isCorrect}
              items={items}
              hasSubmitted={hasSubmitted}
              failureMessage={failureMessage}
              successMessage={successMessage}
              setIsCorrect={setIsCorrect}
              setItems={setItems}
              setCorrectAnswer={setCorrectAnswer}
              correctAnswer={correctAnswer}
              // reset={onDragCancel}
              initialItems={defaultItems}
              setHasSubmitted={setHasSubmitted}
            />
          </div>
        ) : (
          <div
            style={{
              width: '100%',
              height: '100%',
              padding: '100px',
              display: 'flex',
              justifyContent: 'center',
            }}>
            <CircularProgress />
          </div>
        )}
      </ChakraProvider>
    </Box>
  );
};

export const FillText = forwardRef<
  {
    submit: (answers: string[], dAnswers: undefined | string[]) => void;
    getAnswer: () => string[];
  },
  FillTextI
>(FillText1);

export const CorrectFillText: FC<CorrectFillTextI> = ({ children, answers = [] }) => {
  const [defaultItems, childrenWithBlanks] = useMemo(
    () => correctParseItemsFromChildren1(children, answers),
    [children, answers],
  );

  // keys in `items` are the ids of the blanks/droppableContainers
  const [items, setItems] = useState<any>(defaultItems);

  return (
    <Box py='1' maxW='960px' sx={{ width: '100%' }}>
      <ChakraProvider>
        <Flex direction='column' alignItems='flex-start'>
          <div>
            {childrenWithBlanks.map((child: any, index: number) => (
              <CallBlank
                key={index}
                child={child}
                index={index}
                setItems={(e) => console.log(e)}
                setCorrectAnswer={(e) => console.log(e)}
                correctAnswer={answers}
                items={items}
                isMapRemove={(x: string) => console.log(x)}
                disabled={true}
              />
            ))}
          </div>
        </Flex>
      </ChakraProvider>
    </Box>
  );
};
