import React, { FC } from 'react';
import { Item } from './Item';

interface SortableItemI {
  taskId: string;
  isMap: boolean;
  id: string;
  setValue: (x: string) => void;
  isMapRemove?: () => void;
  hasSubmitted?: boolean;
  isCorrect?: boolean;
  disabled?: boolean;
  value?: string;
  haveDrag?: boolean;
}

export const SortableItem2: FC<SortableItemI> = ({
  id,
  isMap,
  taskId,
  setValue,
  isMapRemove,
  isCorrect,
  hasSubmitted,
  disabled,
  value,
  haveDrag,
}) => {
  return (
    <Item
      id={id}
      value={value}
      setValue={setValue}
      isMap={isMap}
      isMapRemove={isMapRemove}
      isCorrect={isCorrect}
      hasSubmitted={hasSubmitted}
      disabled={disabled}
      haveDrag={haveDrag}
    />
  );
};
