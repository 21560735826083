import React, { FC, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import UniversalCookie from 'universal-cookie';
import { useConnection, useUserInfo } from '../connection/Application';
import {
  AppBar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Slide,
  Toolbar,
  Typography,
} from '@mui/material';
import { AUTH_TOKEN } from '../connection/ApplicationContext';
import { TransitionProps } from '@mui/material/transitions';
import { NotificationData } from '../entity/Notification';

// eslint-disable-next-line @typescript-eslint/ban-types
type Props = {};
const universalCookie = new UniversalCookie();

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction='up' ref={ref} {...props} />;
});

export const WebUxHeader: FC<Props> = () => {
  const history = useNavigate();
  const user = useUserInfo();
  const connection = useConnection();
  const [notification, setNotification] = useState<NotificationData[]>([]);
  const [unread, setUnread] = useState<number>(0);
  const [logoutModelVisible, setLogoutModelVisible] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [lastUpdate, setLastUpdate] = useState<Date>();
  const logout = useCallback(() => {
    setLogoutModelVisible(true);
    // Modal.confirm({
    //   title: 'Logout',
    //   icon: <ExclamationCircleOutlined />,
    //   content: 'Are you sure to logout?',
    //   okText: 'Logout',
    //   okType: 'primary',
    //   onOk() {
    //     connection.setToken(undefined);
    //     history('/');
    //   },
    //   onCancel() {
    //     console.log('Cancel');
    //   },
    // });
  }, []);

  const getNotification = useCallback(async () => {
    const token = universalCookie.get(AUTH_TOKEN);
    if (token) {
      await connection.get('notification', { userId: user?.id }).then((response) => {
        setNotification(response);
        const unreadItems = response.filter((item: NotificationData) => item.read == false);
        setUnread(unreadItems.length);
        setLastUpdate(new Date());
      });
    }
  }, [connection, user?.id]);

  const updateNotifiction = useCallback(() => {
    const token = universalCookie.get(AUTH_TOKEN);
    if (token) {
      connection
        .get('notification', { userId: user?.id, date: lastUpdate?.toString() })
        .then((response) => {
          setLastUpdate(new Date());
          const result = [...response, ...notification];
          const unreadItems = result.filter((item: NotificationData) => item.read === false);
          setNotification(result);
          setUnread(unreadItems.length);
        });
    }
  }, [connection, lastUpdate, notification, user?.id]);

  const loadMore = useCallback(() => {
    const token = universalCookie.get(AUTH_TOKEN);
    if (token && notification.length >= page * 100) {
      connection.get('notification', { userId: user?.id, page: page + 1 }).then((response) => {
        const result = [...notification, ...response];
        setNotification(result);
        setPage(page + 1);
        const unreadItems = response.filter((item: NotificationData) => item.read == false);
        setUnread(unread + unreadItems.length);
      });
    }
  }, [connection, notification, page, unread, user?.id]);

  useEffect(() => {
    getNotification();
  }, [getNotification]);

  useEffect(() => {
    const timer = setInterval(() => {
      updateNotifiction();
    }, 5 * 60 * 1000);

    return () => clearInterval(timer); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  }, [updateNotifiction]);

  const navigate: any = useCallback(
    (info: { key: string }) => {
      history(`${info.key}`);
    },
    [history],
  );

  const onRead = useCallback(
    (notificationId: number, all?: boolean) => {
      connection
        .patch(`notification/${user?.id}`, { notificationId, all })
        .then(() => getNotification());
    },
    [connection, getNotification, user?.id],
  );

  // const notifications = (
  //   <List
  //     className="notification-list"
  //     bordered={true}
  //     header={
  //       <>
  //         <div style={{ width: '100%', display: 'flex', flexDirection: 'row-reverse' }}>
  //           <Button type="link" onClick={() => onRead(notification[0].id as number, true)}>
  //             Mark all as Read
  //           </Button>
  //         </div>
  //         <Divider />
  //       </>
  //     }
  //     footer={
  //       notification.length >= page * 100 ? (
  //         <>
  //           <div style={{ width: '100%', display: 'flex', flexDirection: 'row-reverse' }}>
  //             <Button type="link" onClick={() => loadMore()}>
  //               Show old notifications
  //             </Button>
  //           </div>
  //           <Divider />
  //         </>
  //       ) : (
  //         <></>
  //       )
  //     }
  //     itemLayout="horizontal"
  //     dataSource={notification}
  //     renderItem={(item: NotificationData) => (
  //       <List.Item
  //         onClick={() => {
  //           if (item.read == false) {
  //             onRead(item.id as number, false);
  //           }
  //
  //           window.location.replace(item.url);
  //         }}
  //         className="list-item-notification"
  //         style={
  //           item.read
  //             ? {
  //                 backgroundColor: '#ffffff',
  //                 cursor: 'pointer',
  //               }
  //             : {
  //                 backgroundColor: 'rgb(213 240 255)',
  //                 cursor: 'pointer',
  //               }
  //         }>
  //         <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
  //           <div
  //             style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
  //             <div style={{ paddingRight: '15px' }}>
  //               <UserAvatar src={item.image} />
  //             </div>
  //             <Title level={5} ellipsis={{ rows: 1 }}>
  //               {item.title}
  //             </Title>
  //           </div>
  //           <Paragraph ellipsis={{ rows: 2 }} style={{ fontSize: 'small', marginBottom: 0 }}>
  //             {item.description}
  //           </Paragraph>
  //           <p style={{ fontSize: 'x-small' }}>{moment(item.time).fromNow()}</p>
  //           <Divider style={{ backgroundColor: '#0000003d' }} />
  //         </div>
  //       </List.Item>
  //     )}
  //   />
  // );
  //
  // const profileMenu = (
  //   <Menu>
  //     <Menu.Item key="/webux/profile" onClick={navigate} icon={<UserOutlined />}>
  //       <Anchor affix={true} key="/webux/profile" onClick={navigate}>
  //         <Link href="/webux/profile" title="Profile" />
  //       </Anchor>
  //     </Menu.Item>
  //     <Menu.Item key="logout" onClick={logout} icon={<LogoutOutlined />}>
  //       Logout
  //     </Menu.Item>
  //   </Menu>
  // );

  return (
    <AppBar component='nav'>
      <Toolbar>
        <IconButton
          color='inherit'
          aria-label='open drawer'
          edge='start'
          // onClick={handleDrawerToggle}
          sx={{ mr: 2, display: { sm: 'none' } }}>
          {/* <MenuIcon />*/}
        </IconButton>
        <Typography
          variant='h6'
          component='div'
          sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}>
          My Home Tutor
        </Typography>
        <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
          {/* {navItems.map((item) => (*/}
          {/*    <Button key={item} sx={{ color: '#fff' }}>*/}
          {/*        {item}*/}
          {/*    </Button>*/}
          {/* ))}*/}
        </Box>
      </Toolbar>
      <Dialog
        open={logoutModelVisible}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setLogoutModelVisible(false)}
        aria-describedby='alert-dialog-slide-description'>
        {/* eslint-disable-next-line */}
        <DialogTitle>{"Use Google's location service?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-slide-description'>
            Are you sure to logout?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setLogoutModelVisible(false)}>Cancel</Button>
          <Button
            onClick={() => {
              connection.setToken(undefined);
              history('/');
            }}>
            Logout
          </Button>
        </DialogActions>
      </Dialog>
    </AppBar>
    // <Header className="webux-header">
    //   <Space size={'middle'}>
    //     <Dropdown
    //       overlay={notifications}
    //       trigger={['click']}
    //       overlayStyle={{ minWidth: 300, maxWidth: 500 }}>
    //       <Badge count={unread} offset={[-8, 8]}>
    //         <Button type={'link'} shape={'circle'} size={'large'}>
    //           <BellOutlined />
    //         </Button>
    //       </Badge>
    //     </Dropdown>
    //     <Dropdown overlay={profileMenu} trigger={['click']}>
    //       <div style={{ cursor: 'pointer' }}>
    //         <UserAvatar src={user?.image} />
    //         <Text className="profile-name">
    //           {user?.firstName} {user?.lastName}
    //         </Text>
    //       </div>
    //     </Dropdown>
    //   </Space>
    // </Header>
  );
};

export const WebUxFooter: FC<Props> = () => {
  return <Grid style={{ textAlign: 'center' }}> ©2022 Created by </Grid>;
};
