import React, { FC, useCallback, useEffect, useState } from 'react';
import { useConnection, useUserInfo } from '../../connection/Application';
import Box from '@mui/material/Box';
import { styled, useTheme } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { ListItem, Link, ListItemButton, ListItemText } from '@mui/material';
import List from '@mui/material/List';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import { useNavigate } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(0.5),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const Titles: FC<{
  mainTitles: {
    description: string;
    id: string;
    subTitle: {
      description: string;
      id: string;
      title: string;
      orderNum: number;
    }[];
    title: string;
    url?: string;
    pdf?: string;
  }[];
}> = ({ mainTitles }) => {
  const [titles, setTitles] = useState<
    {
      description: string;
      id: string;
      subTitle: any[];
      title: string;
      url?: string;
      pdf?: string;
    }[]
  >(mainTitles);
  const [disable, setDisable] = useState<boolean>(false);
  const connection = useConnection();
  const navigator = useNavigate();
  const user = useUserInfo();

  const loadQ = useCallback((id: string) => {
    if (!user) {
      navigator('/login');
    } else {
      navigator(`/question/${id}`);
    }

    console.log(id);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setTitles(mainTitles);
    };
    fetchData();
  }, [connection, titles, mainTitles]);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box
      sx={{
        width: '100%',
        maxHeight: '70vh',
        overflowY: 'scroll',
        boxShadow: 'rgba(0, 0, 0, 0.56) 0px 22px 70px 4px',
        padding: matches ? '15px' : '40px',
      }}>
      <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}>
        <Masonry gutter={'20px'}>
          {titles.map((e, i) => (
            <Item
              key={i}
              style={{
                boxShadow: '#CDF2CA 5px 5px, #FFDEFA 10px 10px,  #FFC898 15px 15px',
              }}>
              <>
                {!!e.url && e.url !== '' ? (
                  <h2 style={{ textAlign: 'left', paddingLeft: 10 }}>
                    <a href={e.url} target='_blank' rel='noreferrer'>
                      {`${i + 1}) ${e.title}`}
                    </a>
                    {!!e.pdf && e.pdf !== '' && (
                      <a href={e.pdf} target='_blank' rel='noreferrer'>
                        <img src={'/pdf.png'} style={{ width: '30px', paddingLeft: '10px' }} />
                      </a>
                    )}
                  </h2>
                ) : (
                  <h2 style={{ textAlign: 'left', paddingLeft: 10 }}>
                    {`${i + 1}) ${e.title}`}
                    {!!e.pdf && e.pdf !== '' && (
                      <a href={e.pdf} target='_blank' rel='noreferrer'>
                        <img src={'/pdf.png'} style={{ width: '30px', paddingLeft: '10px' }} />
                      </a>
                    )}
                  </h2>
                )}
                <List
                  sx={{
                    // listStyleType: 'decimal',
                    pl: 4,
                    // '& .MuiListItem-root': {
                    //   display: 'list-item',
                    // },
                  }}>
                  {e.subTitle.map((skill, key) => {
                    return (
                      <ListItem
                        key={key}
                        style={{ cursor: 'pointer' }}
                        sx={{
                          paddingLeft: matches ? '4px' : '16px',
                        }}
                        onClick={() => {
                          if (!disable) {
                            loadQ(skill.id);
                          }
                        }}
                        // primaryText={skill.titleLang(this.data.currentLang)}
                        // initiallyOpen={false}
                        // primaryTogglesNestedList={true}
                        // nestedItems={[
                        //   <p>{skill.descLang(this.data.currentLang)}</p>
                        // ]}
                      >
                        {+skill.orderNum}.
                        <Link style={{ cursor: 'pointer', paddingLeft: '10px' }}>
                          <span>{' ' + skill.title}</span>
                        </Link>
                      </ListItem>
                    );
                  })}
                </List>
              </>
            </Item>
          ))}
          {/* {heights.map((height, index) => (*/}
          {/*  <Item key={index} sx={{ height }}>*/}
          {/*    {index + 1}*/}
          {/*  </Item>*/}
          {/* ))}*/}
        </Masonry>
      </ResponsiveMasonry>
    </Box>
  );
};

export default Titles;
