import {
  Avatar,
  Typography,
  Container,
  Grid,
  Button,
  TextField,
  Link,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  FormHelperText,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { FC, useCallback, useEffect, useState } from 'react';
import Header from '../components/Header/header';
import Footer from '../components/Footer/Footer';
import { useNavigate } from 'react-router-dom';
import { useConnection, useUserInfo } from '../connection/Application';
import Swal from 'sweetalert2';
import { Visibility, VisibilityOff } from '@mui/icons-material';

const useStyles = makeStyles((theme: any) => ({
  paper: {
    paddingTop: '5vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const Copyright: FC = () => {
  return (
    <Typography variant='body2' color='textSecondary' align='center'>
      {'Copyright © '}
      <Link color='inherit' href='https://material-ui.com/'>
        Your Website
      </Link>
      {new Date().getFullYear()}
    </Typography>
  );
};

const SignUp: FC = () => {
  const classes = useStyles();
  const navigator = useNavigate();
  const [showPassword, setShowPassword] = React.useState(false);
  const [showPassword1, setShowPassword1] = React.useState(false);
  const [userData, setUserData] = useState<{
    email: string;
    firstName: string;
    lastName: string;
    mobile: string;
    password: string;
    confirmPassword: string;
  }>({
    email: '',
    firstName: '',
    lastName: '',
    mobile: '',
    password: '',
    confirmPassword: '',
  });

  const [loading, setLoading] = useState<boolean>(false);
  const connection = useConnection();
  const user = useUserInfo();

  useEffect(() => {
    if (user) {
      navigator('/');
    }
  }, [user]);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleClickShowPassword1 = () => {
    setShowPassword1(!showPassword1);
  };

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  const [error, setError] = useState<{
    email: string;
    firstName: string;
    lastName: string;
    mobile: string;
    password: string;
    confirmPassword: string;
  }>({
    email: '',
    firstName: '',
    lastName: '',
    mobile: '',
    password: '',
    confirmPassword: '',
  });

  const singUp = useCallback(() => {
    setLoading(true);
    const error1 = {
      email: '',
      firstName: '',
      lastName: '',
      mobile: '',
      password: '',
      confirmPassword: '',
    };
    if (userData.firstName === '') {
      error1.firstName = 'First Name is required';
    }
    if (userData.lastName === '') {
      error1.lastName = 'Last Name is required';
    }
    if (userData.email === '') {
      error1.email = 'Email is required';
      // eslint-disable-next-line
    } else if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(userData.email)) {
      error1.email = 'Email is not valid';
    }
    if (userData.mobile === '') {
      error1.mobile = 'Mobile is required';
      // eslint-disable-next-line
    } else if (
      !/^0(?:(11|21|23|24|25|26|27|31|32|33|34|35|36|37|38|41|45|47|51|52|54|55|57|63|65|66|67|81|912)(0|2|3|4|5|7|9)|7(0|1|2|4|5|6|7|8)\d)\d{6}$/.test(
        userData.mobile,
      )
    ) {
      error1.mobile = 'Mobile is not valid';
    }
    if (userData.password === '') {
      error1.password = 'Password is required';
    } else if (
      !/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/.test(userData.password)
    ) {
      error1.password = 'Password is not valid';
    }
    if (userData.confirmPassword === '' || userData.password !== userData.confirmPassword) {
      error1.confirmPassword = 'Confirm Password not match';
    }
    setError(error1);
    if (
      error1.email === '' &&
      error1.firstName === '' &&
      error1.lastName === '' &&
      error1.mobile === '' &&
      error1.password === '' &&
      error1.confirmPassword === ''
    ) {
      const user1 = { ...userData };
      if (user1.password) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        delete user1.password;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        delete user1.confirmPassword;
      }
      connection
        .post('users', { user: user1, password: userData.password })
        .then((response: any) => {
          navigator('/login');
        })
        .catch(() => {
          setLoading(false);
          Swal.fire({
            title: 'Error!',
            text: 'Register failed. Please Try Again Later',
            icon: 'error',
            confirmButtonText: 'Ok',
          });
        })
        .finally(() => setLoading(false));
    } else {
      setLoading(false);
    }
  }, [userData]);

  return (
    <>
      <Container component='main' maxWidth='xs' sx={{ minHeight: 'calc( 100vh - 128px)' }}>
        {/* <CssBaseline />*/}
        <Header />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}></Avatar>
          <Typography component='h1' variant='h5'>
            Sign up
          </Typography>
          <div className={classes.form}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete='fname'
                  name='firstName'
                  variant='outlined'
                  required
                  fullWidth
                  value={userData.firstName}
                  onChange={(e) => {
                    console.log({ ...userData, firstName: e.target.value });
                    setUserData({ ...userData, firstName: e.target.value });
                  }}
                  id='firstName'
                  helperText={error.firstName}
                  FormHelperTextProps={{ style: { color: '#E53935' } }}
                  label='First Name'
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant='outlined'
                  required
                  fullWidth
                  value={userData.lastName}
                  helperText={error.lastName}
                  onChange={(e) => setUserData({ ...userData, lastName: e.target.value })}
                  id='lastName'
                  label='Last Name'
                  name='lastName'
                  autoComplete='lname'
                  FormHelperTextProps={{ style: { color: '#E53935' } }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant='outlined'
                  required
                  fullWidth
                  value={userData.email}
                  helperText={error.email}
                  onChange={(e) => setUserData({ ...userData, email: e.target.value })}
                  id='email'
                  label='Email Address'
                  name='email'
                  autoComplete='email'
                  FormHelperTextProps={{ style: { color: '#E53935' } }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant='outlined'
                  required
                  fullWidth
                  value={userData.mobile}
                  helperText={error.mobile}
                  onChange={(e) => setUserData({ ...userData, mobile: e.target.value })}
                  id='mobile'
                  label='Mobile'
                  name='mobile'
                  autoComplete='mobile'
                  FormHelperTextProps={{ style: { color: '#E53935' } }}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl style={{ width: '100%' }}>
                  <InputLabel htmlFor='password'>Password</InputLabel>
                  <OutlinedInput
                    required
                    fullWidth
                    value={userData.password}
                    onChange={(e) => setUserData({ ...userData, password: e.target.value })}
                    name='password'
                    label='Password'
                    id='password'
                    autoComplete='password'
                    type={showPassword ? 'text' : 'password'}
                    color={'primary'}
                    endAdornment={
                      <InputAdornment position='end'>
                        <IconButton
                          aria-label='toggle password visibility'
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge='end'>
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  {!!error.password && (
                    <FormHelperText error id='password'>
                      {error.password}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl style={{ width: '100%' }}>
                  <InputLabel htmlFor='confirmPassword'>Confirm Password</InputLabel>
                  <OutlinedInput
                    required
                    fullWidth
                    value={userData.confirmPassword}
                    onChange={(e) => setUserData({ ...userData, confirmPassword: e.target.value })}
                    name='confirmPassword'
                    label='Confirm Password'
                    id='confirmPassword'
                    autoComplete='password'
                    type={showPassword1 ? 'text' : 'password'}
                    color={'primary'}
                    endAdornment={
                      <InputAdornment position='end'>
                        <IconButton
                          aria-label='toggle password visibility'
                          onClick={handleClickShowPassword1}
                          onMouseDown={handleMouseDownPassword}
                          edge='end'>
                          {showPassword1 ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  {!!error.confirmPassword && (
                    <FormHelperText error id='confirmPassword'>
                      {error.confirmPassword}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              {/* <Grid item xs={12}>*/}
              {/*  <FormControlLabel*/}
              {/*    control={<Checkbox value='allowExtraEmails' color='primary' />}*/}
              {/*    label='I want to receive inspiration, marketing promotions and updates via email.'*/}
              {/*  />*/}
              {/* </Grid>*/}
            </Grid>
            <Button
              type='submit'
              fullWidth
              variant='contained'
              color='primary'
              onClick={() => singUp()}
              disabled={loading}
              sx={{ marginTop: 3, marginBottom: 3 }}
              className={classes.submit}>
              Sign Up
            </Button>
            <Grid>
              <Grid item>
                <Link href='/login' variant='body2'>
                  Already have an account? Sign in
                </Link>
              </Grid>
            </Grid>
          </div>
        </div>
      </Container>
      <Footer />
    </>
  );
};

export default SignUp;
