import {
  Breadcrumbs,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Link,
  Radio,
  RadioGroup,
  Snackbar,
  TextField,
  Typography,
} from '@mui/material';
import { TextToSpeech } from 'tts-react';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { makeStyles } from '@mui/styles';
import Timer from '../Timer/Timer';
import MuiAlert from '@mui/material/Alert';
import ReactHtmlParser from 'react-html-parser';
import { useNavigate, useParams } from 'react-router-dom';
import { useConnection, useUserInfo } from '../../connection/Application';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Swal from 'sweetalert2';
import { Dnd, CorrectDnd } from '../Dnd';
import { CorrectFillText } from '../fillText';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

const useStyles = makeStyles({
  ttscls: {
    '& div': {
      alignItems: 'flex-start !important',
      '& aside': {
        margin: '0px !important',
      },
    },
  },
});

export const TTS: FC<{ children: any }> = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.ttscls}>
      <TextToSpeech
        align='horizontal'
        allowMuting={false}
        markBackgroundColor='#55AD66'
        markColor='white'
        markTextAsSpoken
        position='leftCenter'
        rate={1}
        size='small'
        volume={1}>
        <div style={{ paddingTop: '0px' }}>{props.children}</div>
      </TextToSpeech>
    </div>
  );
};

const AnswerContent: FC<{ type: 'text' | 'richText'; text?: string; image?: string }> = ({
  type,
  text,
  image,
}) => {
  return (
    <Grid container spacing={2}>
      {text && (
        <Grid item sm={12}>
          {type === 'text' ? (
            <TTS>
              <div style={{ marginTop: '0px' }}>
                <p style={{ fontSize: '15px' }}>{text}</p>
              </div>
            </TTS>
          ) : (
            <TTS>
              <div style={{ marginTop: '0px' }}>{ReactHtmlParser(text)}</div>
            </TTS>
          )}
        </Grid>
      )}
      {image && (
        <Grid item sm={12}>
          <img
            src={process.env.REACT_APP_File_URL + image}
            alt='My Team'
            style={{ maxWidth: '100%', maxHeight: '100%', marginLeft: '50px' }}
          />
        </Grid>
      )}
    </Grid>
  );
};

const Answer: FC<{
  number: number;
  type: any;
  cType: 'text' | 'richText';
  text?: string;
  image?: string;
  value?: number | boolean;
}> = ({ number, type, cType, text, image, value }) => {
  return (
    <Grid item sm={6}>
      <div style={{ display: 'flex', margin: '10px 0' }}>
        <Typography style={{ margin: '5px 10px', fontSize: '15px' }} fontWeight={700}>
          {number})
        </Typography>
        <FormControlLabel
          value={value}
          control={type}
          style={{ alignItems: 'flex-start' }}
          label={<AnswerContent type={cType} text={text} image={image} />}
        />
      </div>
    </Grid>
  );
};

const QuestionHead: FC<{
  number: number;
  type: 'text' | 'richText' | 'math';
  text?: string;
  image?: string;
  lable1?: string;
  lable2?: string;
}> = ({ number, type, text, image, lable1, lable2 }) => {
  const operator =
    (text ?? '').split('+').length > 1
      ? '+'
      : (text ?? '').split('-').length > 1
      ? '-'
      : (text ?? '').split('*').length > 1
      ? '*'
      : (text ?? '').split('/').length > 1
      ? '÷'
      : '';
  const ss =
    operator === '+'
      ? (text ?? '').split('+')
      : operator === '-'
      ? (text ?? '').split('-')
      : operator === '*'
      ? (text ?? '').split('*')
      : operator === '÷'
      ? (text ?? '').split('/')
      : (text ?? '').split('');
  return (
    <div style={{ display: 'flex', margin: '20px 0', alignItems: 'flex-start' }}>
      <Typography variant='h6' sx={{ fontWeight: 600, fontSize: '16px' }}>
        {number})
      </Typography>
      <div style={{ flex: 1, paddingLeft: '10px' }}>
        {text && (
          <>
            <TTS>
              {type === 'text' ? (
                <p style={{ fontSize: '15px' }}>{text}</p>
              ) : type === 'math' ? (
                <p
                  style={{
                    // fontWeight: 600,
                    display: 'none',
                    fontSize: '15px',
                    margin: '0px !important',
                  }}>
                  {text}
                </p>
              ) : (
                // <Typography style={{ fontSize: '15px', margin: '0px !important' }}>
                //
                // </Typography>
                ReactHtmlParser(text)
              )}
            </TTS>
            {type === 'math' && (
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={{ paddingRight: '70%', textAlign: 'end', width: '100%' }}>
                  <p style={{ fontSize: '15px' }}>{ss[0]}</p>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    paddingRight: 'calc( 70% - 25px )',
                    width: '100%',
                    justifyContent: 'flex-end',
                  }}>
                  <div style={{}}>
                    <p style={{ fontSize: '15px' }}>{ss[1]}</p>
                  </div>
                  <div style={{ width: '25px', textAlign: 'center' }}>
                    <p style={{ fontSize: '15px' }}>{operator}</p>
                  </div>
                </div>
                <div
                  style={{
                    border: '1px solid',
                    marginLeft: '10%',
                    width: 'calc( 20% + 25px )',
                  }}></div>
              </div>
            )}
          </>
        )}
        {lable1 && (
          <div>
            <h5
              color={'primary'}
              style={{ marginBottom: '20px', marginTop: '8px', fontSize: '12px' }}>
              {lable1}
            </h5>
          </div>
        )}
        {image && (
          <Grid item sm={12}>
            <img
              src={image}
              alt='My Team'
              style={{ maxWidth: '90%', maxHeight: '46vh', marginLeft: '50px' }}
            />
          </Grid>
        )}
        {lable2 && (
          <h5
            color={'success'}
            style={{
              marginBottom: '20px',
              marginLeft: '20px',
              marginTop: '8px',
              fontSize: '12px',
            }}>
            {lable2}
          </h5>
        )}
      </div>
    </div>
  );
};

const Alert = React.forwardRef(function Alert(props, ref) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return <MuiAlert ref={ref} elevation={6} variant='filled' {...props} />;
});

// const transform = (node: any, index: number) => {
//   if (node.type === 'text' && node.data.includes('###BLANK###')) {
//     return (
//       <>
//         <>
//           {ReactHtmlParser(node.data.slice(0, node.data.indexOf('###BLANK###')), {
//             transform: transform,
//           })}
//         </>
//         <DroppableContainer
//           items={['1']}
//           id={index + '-2'}
//           isCorrect={false}
//           allBlanksEmpty={true}
//           style={{
//             height: '40px',
//           }}>
//           <SortableItem1
//             key={'sortable-item-'}
//             isMap={false}
//             id={index + '-1'}
//             taskId={'1'}
//             isCorrect={false}
//           />
//         </DroppableContainer>
//         <>
//           {ReactHtmlParser(
//             node.data.slice(node.data.indexOf('###BLANK###') + 11, node.data.length),
//             { transform: transform },
//           )}
//         </>
//       </>
//     );
//   }
// };

const Question: FC<{
  question: any;
  questionId: string;
  setMark: (x: number) => void;
  setCurrentCount: (x: number) => void;
  currentCount: number;
  setCurrent: (x: number) => void;
  current: number;
  setQCount: (x: number) => void;
  qCount: number;
  mark: number;
  time: number;
  answerLength: number;
  xAnswer?: string[];
}> = ({
  question,
  questionId,
  setMark,
  setCurrentCount,
  currentCount,
  setCurrent,
  current,
  setQCount,
  qCount,
  mark,
  time,
  answerLength,
  xAnswer,
}) => {
  const navigator = useNavigate();
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [wrong, setWrong] = useState(false);
  const [answerWidth, setAnswerWidth] = useState(11 * answerLength);
  const [loading, setLoading] = useState<boolean>(false);
  const [loading1, setLoading1] = useState<boolean>(false);
  const [correctAnswer, setCorrectAnswer] = React.useState<string[]>();
  const [correctAnswer1, setCorrectAnswer1] = React.useState<string[]>();
  const [danswers, setDanswers] = useState<any>();
  const connection = useConnection();
  const dndref = useRef<any>([]);

  const handleClick = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  useEffect(() => {
    setAnswerWidth(11 * answerLength);
  }, [answerLength]);

  useEffect(() => {
    const a = [];
    let i = -1;
    while ((i = question.question.indexOf('###BLANK###', i + 1)) >= 0) {
      a.push(i);
    }
    while ((i = question.question.indexOf('###BLANK1###', i + 1)) >= 0) {
      a.push(i);
    }
    setCorrectAnswer(a.length === 0 ? [''] : [...new Array(a.length)].map(() => ''));
  }, [question.question]);

  const handleClose = useCallback(
    (event?: React.SyntheticEvent | Event, reason?: string) => {
      if (reason === 'clickaway') {
        return;
      }

      setOpen(false);
    },
    [setOpen],
  );

  const handleClick1 = useCallback(() => {
    setOpen1(true);
  }, [setOpen1]);

  const handleClose1 = useCallback(
    (event?: React.SyntheticEvent | Event, reason?: string) => {
      if (reason === 'clickaway') {
        return;
      }

      setOpen1(false);
    },
    [setOpen1],
  );

  const submit = useCallback(
    (id: string) => {
      if (question.questionType === 'drag' || question.questionType === 'fillText') {
        setDanswers(correctAnswer);
        setLoading1(true);
      } else {
        setLoading(true);
      }

      connection
        .get(`question/answer/${id}/${time}`)
        .then(async (e) => {
          setCorrectAnswer1(e.response.body.q);
          if (question.questionType === 'text') {
            setQCount(qCount + 1);
            if ((e.response.body.q[0] + '').toLowerCase() === correctAnswer?.[0].toLowerCase()) {
              // setMark((mark * current + 100) / (current + 1));
              setCurrent(current + 1);
              setLoading(false);
              setCorrectAnswer([]);
              handleClick();
            } else {
              setWrong(true);
              // setMark((mark * current) / (current + 1));
              setLoading(false);
              handleClick1();
            }
          } else if (question.questionType === 'multipleSelect') {
            setQCount(qCount + 1);
            const cc = correctAnswer?.filter((e) => e !== '');
            if (
              e.response.body.q.length === (cc ?? []).length &&
              !(cc ?? [])
                .map((s) => !!e.response.body.q.find((d: string) => d === s))
                .find((m) => !m)
            ) {
              setMark((mark * currentCount + 100) / (currentCount + 1));
              setCurrent(current + 1);
              setCurrentCount(currentCount + 1);
              setCorrectAnswer([]);
              setLoading(false);
              handleClick();
            } else {
              setMark((mark * currentCount) / (currentCount + 1));
              setCurrentCount(currentCount + 1);
              setWrong(true);
              setLoading(false);
              handleClick1();
            }
          } else if (question.questionType === 'boolean') {
            setQCount(qCount + 1);
            if (e.response.body.q[0] + '' === correctAnswer?.[0] + '') {
              setMark((mark * currentCount + 100) / (currentCount + 1));
              setCurrent(current + 1);
              setCurrentCount(currentCount + 1);
              setCorrectAnswer([]);
              setLoading(false);
              handleClick();
            } else {
              setMark((mark * currentCount) / (currentCount + 1));
              setCurrentCount(currentCount + 1);
              setWrong(true);
              setLoading(false);
              handleClick1();
            }
          } else if (question.questionType === 'number' || question.questionType === 'math') {
            setQCount(qCount + 1);
            if (+e.response.body.q[0] === +(correctAnswer?.[0] ?? 0)) {
              setMark((mark * currentCount + 100) / (currentCount + 1));
              setCurrent(current + 1);
              setCurrentCount(currentCount + 1);
              setCorrectAnswer([]);
              setLoading(false);
              handleClick();
            } else {
              setMark((mark * currentCount) / (currentCount + 1));
              setCurrentCount(currentCount + 1);
              setWrong(true);
              setLoading(false);
              handleClick1();
            }
          } else if (question.questionType === 'drag') {
            dndref.current[0].submit(e.response.body.q, correctAnswer);
            const mark1 = e.response.body.q.reduce((acc: number, a: string, i: number) => {
              return acc + (a === correctAnswer?.[i] ? 1 : 0);
            }, 0);
            setQCount(qCount + e.response.body.q.length);
            if (mark1 === e.response.body.q.length) {
              setLoading(true);
              setMark(
                (mark * currentCount + mark1 * 100) / (currentCount + e.response.body.q.length),
              );
              setCurrent(current + 1);
              setCurrentCount(currentCount + e.response.body.q.length);
              setCorrectAnswer([]);
              setDanswers(undefined);
              setTimeout(() => {
                setLoading(false);
                setLoading1(false);
                handleClick();
              }, 600);
            } else {
              setDanswers(correctAnswer);
              setMark(
                (mark * currentCount + mark1 * 100) / (currentCount + e.response.body.q.length),
              );
              setCurrentCount(currentCount + e.response.body.q.length);
              setWrong(true);
              setLoading(false);
              setLoading1(false);
              handleClick1();
            }
          } else if (question.questionType === 'fillText') {
            dndref.current[1].submit(e.response.body.q, correctAnswer);
            const mark1 = e.response.body.q.reduce((acc: number, a: string, i: number) => {
              return (
                acc + (a.trim().toLowerCase() === correctAnswer?.[i].trim().toLowerCase() ? 1 : 0)
              );
            }, 0);
            setQCount(qCount + e.response.body.q.length);
            if (mark1 === e.response.body.q.length) {
              setLoading(true);
              setMark(
                (mark * currentCount + mark1 * 100) / (currentCount + e.response.body.q.length),
              );
              setCurrent(current + 1);
              setCurrentCount(currentCount + e.response.body.q.length);
              setCorrectAnswer([]);
              setDanswers(undefined);
              setTimeout(() => {
                setLoading(false);
                setLoading1(false);
                handleClick();
              }, 600);
            } else {
              setDanswers(correctAnswer);
              setMark(
                (mark * currentCount + mark1 * 100) / (currentCount + e.response.body.q.length),
              );
              setCurrentCount(currentCount + e.response.body.q.length);
              setWrong(true);
              setLoading(false);
              setLoading1(false);
              handleClick1();
            }
          } else {
            setQCount(qCount + 1);
            if (e.response.body.q[0] === correctAnswer?.[0]) {
              setMark((mark * currentCount + 100) / (currentCount + 1));
              setCurrent(current + 1);
              setCurrentCount(currentCount + 1);
              setCorrectAnswer([]);
              setLoading(false);
              handleClick();
            } else {
              setMark((mark * currentCount) / (currentCount + 1));
              setCurrentCount(currentCount + 1);
              setWrong(true);
              setLoading(false);
              handleClick1();
            }
          }

          if (e.response.body.timeOut) {
            await Swal.fire({
              title: 'Warning!',
              text: 'Your Free Trial Time Is Over. Please Try Again Next Day',
              icon: 'warning',
              confirmButtonText: 'Ok',
            });
            navigator('/');
          }
        })
        .catch(() => {
          setWrong(false);
          setLoading(false);
        });
    },
    [correctAnswer, connection, time],
  );

  useEffect(() => {
    const handleKeyDown = (e: any) => {
      const key = e.keyCode;
      if (key === 13) {
        if (wrong) {
          setLoading(true);
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          setTimeout(() => {
            setLoading(true);
            setCurrent(current + 1);
            setCorrectAnswer(undefined);
            setDanswers(undefined);
            setWrong(false);
            setLoading(false);
            setLoading1(false);
          }, 200);
        } else {
          submit(questionId);
        }
      }
    };
    document.addEventListener('keydown', handleKeyDown, false);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [wrong, current, questionId, submit]);

  return (
    <>
      {loading ? (
        <div
          style={{
            width: '100%',
            height: '100%',
            position: 'absolute',
            padding: '100px',
            display: 'flex',
            justifyContent: 'center',
          }}>
          <CircularProgress />
        </div>
      ) : (
        <div className={'ql-editor'}>
          {loading1 && (
            <div
              style={{
                width: '100%',
                height: '100%',
                position: 'absolute',
                padding: '100px',
                display: 'flex',
                justifyContent: 'center',
              }}>
              <CircularProgress />
            </div>
          )}
          <FormControl style={{ width: '100%', opacity: loading1 ? 0.5 : 1 }}>
            {question.questionType === 'drag' && !!question.question && !!question?.dAnswer && (
              <>
                <FormGroup>
                  <Dnd
                    ref={(el) => {
                      if (el) {
                        dndref.current[0] = el;
                      }
                    }}
                    taskId='dnd-1'
                    number={current + 1}
                    title=''
                    answers={question?.dAnswer.map((e: string, i: number) => ({ id: i, value: e }))}
                    setCorrectAnswer={setCorrectAnswer}
                    correctAnswer={correctAnswer}
                    dAnswers={danswers}
                    additionalAnswers={
                      (correctAnswer ?? []).length - (question?.dAnswer ?? []).length
                    }
                    haveDrag={question.question.split('###BLANK###').length > 1}
                    xAnswer={xAnswer ?? []}
                    lable1={question?.lable1}
                    lable2={question?.lable2}
                    questionHead={question?.questionHead}
                    questionImage={question?.questionImage}>
                    {ReactHtmlParser(
                      question.question
                        .replaceAll('<span class="ql-ui" contenteditable="false"></span>', '')
                        .replaceAll('contenteditable="false"', '')
                        .replaceAll('&nbsp;&nbsp;&nbsp;&nbsp;', '\t')
                        .replaceAll('&nbsp;', ' '),
                    )}
                  </Dnd>
                </FormGroup>
              </>
            )}
            {question.questionType !== 'drag' && question.questionType !== 'fillText' && (
              <QuestionHead
                number={current + 1}
                text={question.question}
                image={
                  question?.questionImage || question?.questionImage !== ''
                    ? process.env.REACT_APP_File_URL + question.questionImage
                    : undefined
                }
                type={question.questionType === 'math' ? 'math' : question.questionContentType}
                lable1={question.lable1}
                lable2={question?.lable2}
              />
            )}
            {question.questionType === 'math' && (
              <div style={{ marginLeft: 'calc( 10% + 24px )', width: '20%' }}>
                <TextField
                  inputProps={{
                    inputMode: 'numeric',
                    pattern: '[0-9]*',
                    style: { textAlign: 'end' },
                  }}
                  style={{
                    marginTop: -10,
                    marginBottom: 30,
                  }}
                  value={correctAnswer?.[0] ?? 0}
                  label=''
                  id='answer'
                  name='answer'
                  autoComplete='off'
                  onFocus={(event) => {
                    event.target.setAttribute('autocomplete', 'off');
                  }}
                  disabled={wrong}
                  onChange={(e) => {
                    const onlyNums = e.target.value.replace(/[^0-9,^.]/g, '');
                    if (onlyNums.split('.').length < 3) {
                      if (onlyNums.length < 10) {
                        setCorrectAnswer([onlyNums + '']);
                      } else if (onlyNums.length === 10) {
                        const number = onlyNums.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
                        setCorrectAnswer([number + '']);
                      }
                    }
                  }}
                />
              </div>
            )}
            <FormGroup>
              <Grid container>
                {question.questionType === 'boolean' && (
                  <RadioGroup
                    row
                    aria-labelledby='demo-radio-buttons-group-label'
                    value={correctAnswer?.[0]}
                    defaultChecked={true}
                    id='answer'
                    name='answer'
                    onChange={(e) => {
                      setCorrectAnswer([e.target.value]);
                    }}>
                    <FormControlLabel
                      value='true'
                      control={<Radio disabled={wrong} />}
                      label='True'
                    />
                    <FormControlLabel
                      value='false'
                      control={<Radio disabled={wrong} />}
                      label='False'
                    />
                  </RadioGroup>
                )}
                {question.questionType === 'text' &&
                  (answerWidth < 832 ? (
                    <TextField
                      style={{
                        textAlign: 'left',
                        marginTop: 30,
                        width: +(answerWidth + 28) + 'px',
                      }}
                      value={correctAnswer?.[0]}
                      id='answer'
                      name='answer'
                      autoComplete='off'
                      onFocus={(event) => {
                        event.target.setAttribute('autocomplete', 'off');
                      }}
                      disabled={wrong}
                      onChange={(e) => {
                        setCorrectAnswer([e.target.value]);
                      }}
                    />
                  ) : (
                    <TextField
                      multiline
                      rows={Math.ceil(answerWidth / 832)}
                      fullWidth
                      style={{ textAlign: 'left', marginTop: 30 }}
                      value={correctAnswer?.[0]}
                      label='Answer'
                      id='answer'
                      name='answer'
                      autoComplete='off'
                      onFocus={(event) => {
                        event.target.setAttribute('autocomplete', 'off');
                      }}
                      disabled={wrong}
                      onChange={(e) => {
                        setCorrectAnswer([e.target.value]);
                      }}
                    />
                  ))}
                {question.questionType === 'textArea' && (
                  <TextField
                    multiline
                    rows={4}
                    fullWidth
                    style={{ textAlign: 'left', marginTop: 30 }}
                    value={correctAnswer?.[0]}
                    label='Answer'
                    id='answer'
                    name='answer'
                    autoComplete='off'
                    onFocus={(event) => {
                      event.target.setAttribute('autocomplete', 'off');
                    }}
                    disabled={wrong}
                    onChange={(e) => {
                      setCorrectAnswer([e.target.value]);
                    }}
                  />
                )}
                {question.questionType === 'number' && (
                  <TextField
                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                    style={{ textAlign: 'left', marginTop: 30, width: +(answerWidth + 28) + 'px' }}
                    value={correctAnswer?.[0] ?? 0}
                    id='answer'
                    name='answer'
                    autoComplete='off'
                    onFocus={(event) => {
                      event.target.setAttribute('autocomplete', 'off');
                    }}
                    disabled={wrong}
                    onChange={(e) => {
                      const onlyNums = e.target.value.replace(/[^0-9,^.]/g, '');
                      if (onlyNums.split('.').length < 3) {
                        if (onlyNums.length < 10) {
                          setCorrectAnswer([onlyNums + '']);
                        } else if (onlyNums.length === 10) {
                          const number = onlyNums.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
                          setCorrectAnswer([number + '']);
                        }
                      }
                    }}
                  />
                )}
              </Grid>
              {question.questionType === 'multipleSelect' && (
                <Grid container spacing={2}>
                  {question.answer.map((e: any, i: number) => (
                    <Answer
                      key={i}
                      number={i + 1}
                      text={e.answer}
                      cType={e.answerContentType}
                      image={
                        // eslint-disable-next-line
                        e?.answerImage !== undefined || e?.answerImage !== ''
                          ? e?.answerImage
                          : undefined
                      }
                      value={!!(correctAnswer ?? []).find((e) => e === i + '')}
                      type={
                        <Checkbox
                          disabled={wrong}
                          checked={!!(correctAnswer ?? []).find((e) => e === i + '')}
                          onChange={(e) => {
                            setCorrectAnswer(
                              e.target.value === 'false'
                                ? [...(correctAnswer ?? []), i + '']
                                : (correctAnswer ?? []).filter((x) => x !== i + ''),
                            );
                          }}
                          sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                          style={{ paddingTop: '5px' }}
                        />
                      }
                    />
                  ))}
                </Grid>
              )}
            </FormGroup>
            {question.questionType === 'select' && (
              <RadioGroup
                aria-labelledby='demo-radio-buttons-group-label'
                value={correctAnswer?.[0]}
                onChange={(e) => setCorrectAnswer([(e.target as HTMLInputElement).value])}
                name='radio-buttons-group'>
                <Grid container spacing={2}>
                  {question.answer.map((e: any, i: number) => (
                    <Answer
                      key={i}
                      number={i + 1}
                      value={i}
                      text={e.answer}
                      cType={e.answerContentType}
                      image={
                        // eslint-disable-next-line
                        e?.answerImage !== undefined || e?.answerImage !== ''
                          ? e?.answerImage
                          : undefined
                      }
                      type={<Radio disabled={wrong} style={{ paddingTop: '5px' }} />}
                    />
                  ))}
                </Grid>
              </RadioGroup>
            )}
            {wrong && (
              <Button
                color={'success'}
                variant='contained'
                onClick={() => {
                  setLoading(true);
                  // eslint-disable-next-line @typescript-eslint/no-empty-function
                  setTimeout(() => {
                    setLoading(true);
                    setCurrent(current + 1);
                    setCorrectAnswer(undefined);
                    setDanswers(undefined);
                    setWrong(false);
                    setLoading(false);
                    setLoading1(false);
                  }, 200);
                }}
                sx={{
                  width: '150px',
                  fontSize: '16px',
                  borderRadius: 10,
                  margin: '0 0 auto auto',
                }}>
                Next
              </Button>
            )}
            {!wrong && (
              <Button
                color={'primary'}
                variant='contained'
                onClick={() => submit(questionId)}
                sx={{
                  width: '150px',
                  fontSize: '16px',
                  borderRadius: 10,
                  margin: '0 0 auto auto',
                }}>
                submit
              </Button>
            )}
            {wrong && (
              <Grid item sm={12} style={{ marginBottom: '20px' }}>
                <Typography
                  variant='h3'
                  color={'#35a845'}
                  sx={{ fontWeight: 700, marginTop: '5px', marginBottom: '5px', fontSize: '18px' }}>
                  Correct Answer
                </Typography>
                <FormGroup>
                  <Grid container>
                    {question.questionType === 'boolean' && (
                      <RadioGroup
                        row
                        aria-labelledby='demo-radio-buttons-group-label'
                        value={correctAnswer1?.[0]}
                        defaultChecked={true}
                        id='answer'
                        name='answer'
                        onChange={(e) => {
                          //
                        }}>
                        <FormControlLabel value='true' control={<Radio />} label='True' />
                        <FormControlLabel value='false' control={<Radio />} label='False' />
                      </RadioGroup>
                    )}
                    {question.questionType === 'text' &&
                      (answerWidth < 832 ? (
                        <TextField
                          style={{
                            textAlign: 'left',
                            marginTop: 30,
                            width: +(answerWidth + 28) + 'px',
                          }}
                          value={correctAnswer1?.[0]}
                          id='answer'
                          name='answer'
                          onChange={(e) => {
                            //
                          }}
                        />
                      ) : (
                        <TextField
                          multiline
                          rows={Math.ceil(answerWidth / 832)}
                          fullWidth
                          style={{ textAlign: 'left', marginTop: 30 }}
                          value={correctAnswer1?.[0]}
                          label='Answer'
                          id='answer'
                          name='answer'
                          onChange={(e) => {
                            //
                          }}
                        />
                      ))}
                    {question.questionType === 'textArea' && (
                      <TextField
                        fullWidth
                        multiline
                        rows={4}
                        style={{ textAlign: 'left', marginTop: 30 }}
                        value={correctAnswer1?.[0]}
                        label='Answer'
                        id='answer'
                        name='answer'
                        onChange={(e) => {
                          //
                        }}
                      />
                    )}
                    {question.questionType === 'number' && (
                      <TextField
                        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                        style={{
                          textAlign: 'left',
                          marginTop: 30,
                          width: +(answerWidth + 28) + 'px',
                        }}
                        value={correctAnswer1?.[0]}
                        id='answer'
                        name='answer'
                        onChange={(e) => {
                          //
                        }}
                      />
                    )}
                  </Grid>
                  {question.questionType === 'multipleSelect' && (
                    <Grid container spacing={2}>
                      {question.answer.map((e: any, i: number) => (
                        <Answer
                          key={i}
                          number={i + 1}
                          text={e.answer}
                          cType={e.answerContentType}
                          image={
                            // eslint-disable-next-line
                            e?.answerImage !== undefined || e?.answerImage !== ''
                              ? e?.answerImage
                              : undefined
                          }
                          value={!!(correctAnswer1 ?? []).find((e) => e === i + '')}
                          type={
                            <Checkbox
                              checked={!!(correctAnswer1 ?? []).find((e) => e === i + '')}
                              onChange={(e) => {
                                //
                              }}
                              sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                              style={{ paddingTop: '5px' }}
                            />
                          }
                        />
                      ))}
                    </Grid>
                  )}
                </FormGroup>
                {question.questionType === 'select' && (
                  <RadioGroup
                    aria-labelledby='demo-radio-buttons-group-label'
                    value={correctAnswer1?.[0]}
                    onChange={(e) => {
                      //
                    }}
                    name='radio-buttons-group'>
                    <Grid container spacing={2}>
                      {question.answer.map((e: any, i: number) => (
                        <Answer
                          key={i}
                          number={i + 1}
                          value={i}
                          text={e.answer}
                          cType={e.answerContentType}
                          image={
                            // eslint-disable-next-line
                            e?.answerImage !== undefined || e?.answerImage !== ''
                              ? e?.answerImage
                              : undefined
                          }
                          type={<Radio style={{ paddingTop: '5px' }} />}
                        />
                      ))}
                    </Grid>
                  </RadioGroup>
                )}
              </Grid>
            )}
            {wrong && question.questionType === 'math' && (
              <>
                <QuestionHead
                  number={current + 1}
                  text={question.question}
                  image={
                    question?.questionImage || question?.questionImage !== ''
                      ? process.env.REACT_APP_File_URL + question.questionImage
                      : undefined
                  }
                  type={question.questionType === 'math' ? 'math' : question.questionContentType}
                />
                <div style={{ marginLeft: 'calc( 10% + 24px )', width: '20%' }}>
                  <TextField
                    inputProps={{
                      inputMode: 'numeric',
                      pattern: '[0-9]*',
                      style: { textAlign: 'end' },
                    }}
                    style={{
                      marginTop: -10,
                      marginBottom: 30,
                    }}
                    value={correctAnswer1?.[0]}
                    id='answer'
                    name='answer'
                    onChange={(e) => {
                      //
                    }}
                  />
                </div>
              </>
            )}
            {wrong && question.questionType === 'drag' && (
              <CorrectDnd answers={correctAnswer1} xAnswer={xAnswer ?? []}>
                {ReactHtmlParser(
                  question.question
                    .replaceAll('<span class="ql-ui" contenteditable="false"></span>', '')
                    .replaceAll('contenteditable="false"', '')
                    .replaceAll('&nbsp;&nbsp;&nbsp;&nbsp;', '\t')
                    .replaceAll('&nbsp;', ' '),
                )}
              </CorrectDnd>
            )}
            {wrong && question.questionType === 'fillText' && (
              <CorrectFillText answers={correctAnswer1}>
                {ReactHtmlParser(
                  question.question
                    .replaceAll('<span class="ql-ui" contenteditable="false"></span>', '')
                    .replaceAll('contenteditable="false"', '')
                    .replaceAll('&nbsp;&nbsp;&nbsp;&nbsp;', '\t')
                    .replaceAll('&nbsp;', ' '),
                )}
              </CorrectFillText>
            )}
            <Snackbar open={open} autoHideDuration={2000} onClose={handleClose}>
              {/* <Alert onClose={handleClose} severity='success' sx={{ width: '100%' }}>*/}
              {/*  This is a success message!*/}
              {/* </Alert>*/}
              <div>
                {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                {/* @ts-ignore*/}
                <Alert onClose={handleClose} severity='success' sx={{ width: '100%' }}>
                  You Answer Is Correct!
                </Alert>
              </div>
            </Snackbar>
            <Snackbar open={open1} autoHideDuration={2000} onClose={handleClose1}>
              {/* <Alert onClose={handleClose} severity='success' sx={{ width: '100%' }}>*/}
              {/*  This is a success message!*/}
              {/* </Alert>*/}
              <div>
                {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                {/* @ts-ignore*/}
                <Alert onClose={handleClose1} severity='error' sx={{ width: '100%' }}>
                  You Answer Is Wrong!
                </Alert>
              </div>
            </Snackbar>
          </FormControl>
        </div>
      )}
    </>
  );
};

export const QuestionSet: FC = () => {
  const timerRef = useRef<any>();
  const connection = useConnection();
  const navigator = useNavigate();
  const params = useParams<{ subTitleId: string }>();
  const [subTitle, setSubTitle] = useState('');
  const [seconds, setSeconds] = useState(0);
  const [mainTitle, setMainTitle] = useState('');
  const [questions, setQuestions] = useState<any[]>([]);
  const [mark, setMark] = useState(0);
  const [current, setCurrent] = useState(0);
  const [currentCount, setCurrentCount] = useState(0);
  const [qCount, setQCount] = useState(0);
  const user = useUserInfo();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const ddRef = useRef<number>(0);

  useEffect(() => {
    if (!user) {
      navigator('/login');
    }
  }, [user]);

  const getSeconds = useCallback((x: number) => {
    setSeconds(x);
  }, []);

  useEffect(() => {
    // eslint-disable-next-line no-empty
    if (current >= questions.length && current !== 0) {
      timerRef.current?.toggle(false);
    }
  }, [current, questions]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (!params.subTitleId) {
      navigator('/');
    } else {
      connection.get(`sub-title/questions/${params.subTitleId}`).then(async (e) => {
        if (e.response.timeOut) {
          await Swal.fire({
            title: 'Warning!',
            text: 'Your Free Trial Time Is Over. Please Try Again Next Day',
            icon: 'warning',
            confirmButtonText: 'Ok',
          });
          navigator('/');
        }
        if (e.response.data.length === 0) {
          navigator('/');
        } else {
          setSubTitle(e.response.data[0].subTitle.value);
          ++ddRef.current;
          if (subTitle !== e.response.data[0].subTitle.value && ddRef.current === 1) {
            setMainTitle(e.response.data[0].mainTitle.value);
            setCurrent(0);
            setQCount(0);
            setMark(0);
            setQuestions(e.response.data);
          }
        }
        timerRef.current?.toggle(true);
      });
    }
  }, []);
  return (
    <Grid
      container
      spacing={2}
      direction={matches ? 'column-reverse' : 'row'}
      style={{ marginTop: '100px' }}>
      <Grid item sm={12} md={9} style={{ position: 'relative' }}>
        {questions.length > 0 && (
          <Breadcrumbs
            separator={<NavigateNextIcon fontSize='small' />}
            style={{ paddingBottom: '15px' }}
            aria-label='breadcrumb'>
            [
            <Link
              underline='hover'
              key='1'
              color='inherit'
              style={{ fontWeight: 'bolder', fontSize: '14px' }}
              href={`/?category=${questions[0]?.category?.id}&grade=${questions[0]?.grade?.id}`}>
              {questions[0]?.grade?.value}
            </Link>
            ,
            <Link
              underline='hover'
              key='2'
              color='inherit'
              style={{ fontWeight: 'bolder', fontSize: '14px' }}
              href={`/?category=${questions[0]?.category?.id}&grade=${questions[0]?.grade?.id}`}>
              {questions[0]?.category?.value}
            </Link>
            ,
            <Link
              underline='hover'
              key='3'
              color='inherit'
              style={{ fontWeight: 'bolder', fontSize: '14px' }}
              href={`/?category=${questions[0]?.category?.id}&grade=${questions[0]?.grade?.id}`}>
              {questions[0]?.mainTitle?.value}
            </Link>
            ,
            <Link
              underline='hover'
              key='3'
              color='inherit'
              style={{ fontWeight: 'bolder', fontSize: '14px' }}
              href={`/question/${questions[0]?.subTitle?.id}`}>
              {(questions[0]?.subTitle?.orderNum ?? '').toLocaleString()}
            </Link>
            , ]
          </Breadcrumbs>
        )}
        <Grid item sm={12} style={{ paddingBottom: '8px' }}>
          <Typography variant='h3' sx={{ fontWeight: 700, fontSize: '21px' }}>
            {mainTitle}
          </Typography>
        </Grid>
        <Grid item sm={12}>
          <Typography variant='h5' sx={{ fontWeight: 700, fontSize: '15px' }}>
            {subTitle}
          </Typography>
        </Grid>
        {questions.length !== 0 && current < questions.length && (
          <Question
            current={current}
            currentCount={currentCount}
            mark={mark}
            question={{
              ...questions[current].question,
              dAnswer: questions[current]?.answer,
            }}
            questionId={questions[current].id}
            setMark={setMark}
            time={seconds}
            setCurrent={setCurrent}
            setCurrentCount={setCurrentCount}
            setQCount={setQCount}
            qCount={qCount}
            answerLength={+(questions[current]?.answerLength ?? 1)}
            xAnswer={questions?.[current]?.xAnswer ?? ['BB']}
          />
        )}
        {current >= questions.length && (
          <Button
            color={'primary'}
            variant='contained'
            onClick={() => navigator('/')}
            sx={{
              width: '150px',
              fontSize: '16px',
              borderRadius: 10,
              margin: '60px 0 auto auto',
            }}>
            Go Home
          </Button>
        )}
      </Grid>
      <Grid item sm={12} md={3}>
        <Timer ref={timerRef} mark={Math.round(mark)} getSeconds={getSeconds} />
      </Grid>
    </Grid>
  );
};
