import React, { FC, useEffect, useRef, useState } from 'react';
import { useConnection } from '../../connection/Application';
import { makeStyles } from '@mui/styles';
import Tabs, { TabsActions } from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useQueryParam } from 'use-query-params';

const useStyles = makeStyles({
  nav: {
    position: 'sticky',
    top: 0,
    width: '100%',
    maxWidth: '1760px',
    margin: '0 auto',
    zIndex: 1,
  },
  oval: {
    display: 'flex',
    justifyContent: 'flex-start',
    width: '100%',
    height: '100%',
    borderRadius: '40px',
    background: '#95a18b',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    overflow: 'auto',
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    height: '95px',
    margin: '0 8px',
    paddingTop: '19px',
    gap: '8px',
    transition: 'height 300ms cubic-bezier(0.2, 0, 0, 1)',
    borderRadius: '0 0 40px 40px',
  },
  tab: {
    display: 'inline-flex !important',
    position: 'relative',
    flexGrow: 1,
    alignItems: 'center !important',
    justifyContent: 'center !important',
    padding: '17px 24px !important',
    border: 'none !important',
    borderRadius: '40px !important',
    minWidth: '180px !important',
    lineHeight: '23px !important',
    cursor: 'pointer !important',
    verticalAlign: 'middle !important',
    '& tab:hover': {
      background: '#adb99f',
      color: 'black',
    },
  },
  active: {
    background: '#4d6936 !important',
  },
});

// #4d6936

const Category: FC<{ sticky: boolean }> = ({ sticky }) => {
  const [category, setCategory] = useQueryParam<string | undefined>('category');
  const [value, setValue] = useState<number>(-1);
  const [categories, setCategories] = useState<{ category: string; id: string }[]>([]);
  const connection = useConnection();
  const classes = useStyles();
  const ref = useRef<TabsActions | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      const category1 = await connection.get('category/data/all');
      setCategories(
        category1.response.body.map((e: any, i: number) => ({
          category: e.value,
          id: e.id,
        })),
      );
      if (category) {
        setValue(category1.response.body.findIndex((e: any) => e.id === category));
      }
    };
    fetchData();
  }, [connection]);

  useEffect(() => {
    setTimeout(() => {
      ref.current?.updateIndicator();
      ref.current?.updateScrollButtons();
    }, 700);
  }, [sticky]);

  useEffect(() => {
    if (category) {
      setValue(categories.findIndex((e) => e.id === category));
    } else {
      setValue(-1);
    }
  }, [category]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    setCategory(categories[newValue].id);
  };
  return (
    <nav role='presentation' className={classes.nav} aria-label='article tabs'>
      <div className={classes.container}>
        <Tabs
          action={ref}
          sx={{
            overflow: 'auto',
            '& .MuiTabs-scrollButtons.Mui-disabled': {
              opacity: 0.3,
            },
          }}
          TabIndicatorProps={{ style: { background: 'none' } }}
          className={classes.oval}
          variant='scrollable'
          scrollButtons={categories.length > 4 ? (categories.length > 6 ? true : 'auto') : false}
          value={value}
          onChange={handleChange}
          aria-label='icon label tabs example'>
          {categories.map((e, i) => (
            <Tab
              key={e.id}
              className={classes.tab}
              sx={{
                backgroundColor: value === i ? '#4d6936 !important' : '#95a18b',
                color: value === i ? 'white !important' : 'white',
              }}
              label={e.category}
            />
          ))}
        </Tabs>
      </div>
    </nav>
  );
};

export default Category;
