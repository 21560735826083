import {
  AppBar,
  Typography,
  Link,
  Box,
  Toolbar,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Drawer,
  Button,
  Menu,
} from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import React, { FC, useCallback, useState } from 'react';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import MenuIcon from '@mui/icons-material/Menu';
import useStyles from '../../styles/styles';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useNavigate } from 'react-router-dom';
import { useConnection, useUserInfo } from '../../connection/Application';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import NotificationItem from '../Items/NotificationItem';

function ElevationScroll(props: any) {
  const { children } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

const links: {
  route: string;
  url: string;
  button: boolean;
  color?:
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'success'
    | 'error'
    | 'info'
    | 'warning'
    | undefined;
}[] = [
  { route: 'Home', url: '/', button: false },
  // { route: 'About Us', url: '/about', button: false },
  // { route: 'Contact Us', url: '/contact', button: false },
  // { route: 'Testimonial', url: '/testimonial', button: false },
  { route: 'Sign Up', url: '/signup', button: true, color: 'primary' },
  { route: 'Login', url: '/login', button: true, color: 'secondary' },
];

const sLinks: {
  route: string;
  url: string;
  left: boolean;
}[] = [
  { route: 'Learning', url: '/', left: true },
  { route: 'Assessment', url: '/', left: true },
  { route: 'Analytics', url: '/', left: true },
  { route: 'Takeoff', url: '/', left: true },
  { route: 'Inspiration', url: '/', left: false },
  { route: 'MemberShip', url: '/', left: false },
];

const LinkList: FC<{
  anchor: string;
  toggleDrawer: (anchor: string, open: boolean, event: any) => void;
  onRouteClick: (link: string) => void;
}> = ({ anchor, toggleDrawer, onRouteClick }) => {
  const user = useUserInfo();
  return (
    <Box
      role='presentation'
      onClick={(event) => toggleDrawer(anchor, false, event)}
      onKeyDown={(event) => toggleDrawer(anchor, false, event)}>
      <List>
        {!user
          ? links.map((link: { route: string; url: string; button: boolean }, i) => (
              <ListItem button key={i}>
                <ListItemText onClick={() => onRouteClick(link.url)} primary={link.route} />
              </ListItem>
            ))
          : links
              .filter((d) => !d.button)
              .map((link: { route: string; url: string; button: boolean }, i) => (
                <ListItem button key={i}>
                  <ListItemText onClick={() => onRouteClick(link.url)} primary={link.route} />
                </ListItem>
              ))}
      </List>
    </Box>
  );
};

Object.defineProperty(String.prototype, 'capitalize', {
  value: function () {
    return this.charAt(0).toUpperCase() + this.slice(1);
  },
  enumerable: false,
});

const Header: FC = () => {
  const MySwal = withReactContent(Swal);
  const classes = useStyles();
  const user = useUserInfo();
  const navigate = useNavigate();
  const connection = useConnection();
  const [openMenu1, setOpenMenu1] = useState<any>(false);

  const logoutUser = async () => {
    // console.log('logout user from the website');

    MySwal.fire({
      title: 'Are you sure?',
      text: 'Do You want Logout this Site!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#dc3545',
      cancelButtonColor: '#4250ce',
      confirmButtonText: 'Yes, Logout it!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        // MySwal.fire({
        //   title: 'Processing!',
        //   didOpen: () => {
        //     MySwal.showLoading();
        //   },
        //   allowOutsideClick: () => !MySwal.isLoading(),
        // }).then(async () => {
        //   console.log('');
        // });
        try {
          connection.setToken(undefined);
          navigate('/sign-in');
        } catch (e: any) {
          if (e.message) {
            await MySwal.fire('Error!', e.message, 'error');
          }
        }
      }
    });
  };

  const renderMenu1 = () => (
    <Menu
      anchorEl={openMenu1}
      anchorReference={undefined}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={Boolean(openMenu1)}
      onClose={handleCloseMenu1}
      sx={{ mt: 2 }}>
      <Link onClick={logoutUser}>
        <NotificationItem icon={<PowerSettingsNewIcon />} title='logout' />
      </Link>
    </Menu>
  );

  const handleOpenMenu1 = (event: any) => setOpenMenu1(event.currentTarget);
  const handleCloseMenu1 = () => setOpenMenu1(false);

  const [state, setState] = useState({ right: false });

  const toggleDrawer = useCallback((anchor: string, open: boolean, event: any) => {
    if (event?.type === 'keydown' && (event?.key === 'Tab' || event?.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  }, []);

  const onRouteClick = useCallback(
    (link: string) => {
      navigate(link, { replace: false });
    },
    [navigator],
  );

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box sx={{ marginBottom: '104px' }}>
      <ElevationScroll>
        <AppBar style={{ background: 'transparent', boxShadow: 'none' }}>
          <Toolbar className={classes.toolBar}>
            <Link href='/' underline='none'>
              <Typography variant='h5' className={classes.logo}>
                My Home Tutor
              </Typography>
            </Link>
            {matches ? (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {user && (
                  <Box>
                    <Box color={'white'}>
                      <IconButton
                        size='large'
                        edge='end'
                        color='inherit'
                        disableRipple
                        aria-controls='notification-menu'
                        aria-haspopup='true'
                        // variant='contained'
                        onClick={handleOpenMenu1}>
                        <Typography style={{ color: 'black' }}>
                          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                          {/* @ts-ignore*/}
                          {user?.firstName?.capitalize() + ' '}
                        </Typography>
                        <AccountCircleIcon
                          style={{ width: 40, height: 40, marginLeft: 5 }}
                          className={classes.menuIcon}
                        />
                      </IconButton>
                      {renderMenu1()}
                    </Box>
                  </Box>
                )}
                <Box>
                  <IconButton
                    size='large'
                    edge='end'
                    color='inherit'
                    aria-label='menu'
                    onClick={(event) => toggleDrawer('right', true, event)}>
                    <MenuIcon className={classes.menuIcon} />
                  </IconButton>

                  <Drawer
                    anchor='right'
                    open={state['right']}
                    onClose={(event) => toggleDrawer('right', false, event)}>
                    {
                      <LinkList
                        anchor={'right'}
                        toggleDrawer={toggleDrawer}
                        onRouteClick={onRouteClick}
                      />
                    }
                  </Drawer>
                </Box>
              </div>
            ) : (
              <>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    flex: 1,
                    alignItems: 'center',
                  }}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      flexGrow: '0.4',
                    }}>
                    {links
                      .filter((e) => !e.button)
                      .map((link, i) => (
                        <Link
                          onClick={() => onRouteClick(link.url)}
                          underline='none'
                          sx={{ cursor: 'pointer' }}
                          key={i}>
                          <Typography className={classes.link}>{link.route}</Typography>
                        </Link>
                      ))}
                  </Box>
                </Box>
                {!user ? (
                  <Box
                    sx={{
                      display: 'flex',
                      flex: 0.6,
                      justifyContent: 'flex-end',
                      alignItems: 'center',
                    }}>
                    {links
                      .filter((e) => e.button)
                      .map((link, i) => (
                        <Button
                          key={i}
                          onClick={() => onRouteClick(link.url)}
                          color={link?.color ?? 'primary'}
                          variant='contained'
                          sx={{
                            width: '150px',
                            fontSize: '16px',
                            borderRadius: '15px',
                            marginLeft: '20px',
                          }}>
                          {link.route}
                        </Button>
                      ))}
                  </Box>
                ) : (
                  <Box>
                    <Box color={'white'}>
                      <IconButton
                        size='large'
                        edge='end'
                        color='inherit'
                        disableRipple
                        aria-controls='notification-menu'
                        aria-haspopup='true'
                        // variant='contained'
                        onClick={handleOpenMenu1}>
                        <Typography style={{ color: 'black' }}>
                          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                          {/* @ts-ignore*/}
                          {user?.firstName?.capitalize() + ' '}
                        </Typography>
                        <AccountCircleIcon
                          style={{ width: 40, height: 40, marginLeft: 5 }}
                          className={classes.menuIcon}
                        />
                      </IconButton>
                      {renderMenu1()}
                    </Box>
                  </Box>
                )}
              </>
            )}
          </Toolbar>
          <Toolbar style={{ minHeight: '40px', backgroundColor: '#26367d' }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                flex: 1,
                alignItems: 'center',
              }}>
              <Box
                sx={{
                  display: 'flex',
                  // justifyContent: 'space-between',
                  alignItems: 'left',
                  flexGrow: '0.4',
                }}>
                {sLinks
                  .filter((e) => e.left)
                  .map((link, i) => (
                    <Link
                      onClick={() => onRouteClick(link.url)}
                      underline='none'
                      paddingRight={8}
                      key={i}>
                      <Typography className={classes.link} style={{ color: 'white' }}>
                        {link.route}
                      </Typography>
                    </Link>
                  ))}
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  // justifyContent: 'space-between',
                  alignItems: 'right',
                  flexGrow: '0.4',
                }}>
                {sLinks
                  .filter((e) => !e.left)
                  .map((link, i) => (
                    <Link
                      onClick={() => onRouteClick(link.url)}
                      paddingRight={8}
                      underline='none'
                      key={i}>
                      <Typography className={classes.link} style={{ color: 'white' }}>
                        {link.route}
                      </Typography>
                    </Link>
                  ))}
              </Box>
            </Box>
            {/* Learning English Skills Textbooks Diagnostic Analytics MEMBERSHIP*/}
          </Toolbar>
          {/* <img*/}
          {/*  src={'/cloud.png'}*/}
          {/*  className={classes.dropShadow}*/}
          {/*  style={{*/}
          {/*    width: '100%',*/}
          {/*    height: '60px',*/}
          {/*    marginTop: '-25px',*/}
          {/*    filter:*/}
          {/*      'drop-shadow(0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);)',*/}
          {/*  }}*/}
          {/* />*/}
        </AppBar>
      </ElevationScroll>
    </Box>
  );
};

export default Header;
