import { Container, CssBaseline } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { FC, useEffect } from 'react';
import Header from '../components/Header/header';
import Footer from '../components/Footer/Footer';
import { QuestionSet } from '../components/Question/Question';

const useStyles = makeStyles((theme: any) => ({
  paper: {
    paddingTop: '15vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const QPage: FC = () => {
  return (
    <>
      <Container sx={{ minHeight: 'calc( 100vh - 128px)' }}>
        <CssBaseline />
        <Header />
        <QuestionSet />
      </Container>
      <Footer />
    </>
  );
};

export default QPage;
