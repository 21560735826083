import * as React from 'react';
import Grade from './../components/Grades/Grade';
import Category from '../components/Category/Category';
import { makeStyles } from '@mui/styles';
import { useEffect, useRef } from 'react';
import { useQueryParam } from 'use-query-params';
import Header from '../components/Header/header';
import Banner from '../components/Banner/Banner';
import Footer from '../components/Footer/Footer';

const navItems = ['Home', 'About', 'Contact'];

function myFunction(): boolean {
  const header = document.getElementById('myHeader');
  const sticky = header?.offsetTop ?? 0;
  if (window.pageYOffset > 150) {
    return true;
  } else {
    return false;
  }
}

const useStyles = makeStyles({
  sticky: {
    maxWidth: '1040px',
    marginBottom: '16px',
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: '00px',
  },
  nonSticky: {
    padding: '10px',
    maxWidth: '1760px',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
});

export default function Home() {
  const [category] = useQueryParam<string | undefined>('category');
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [sticky, setSticky] = React.useState(false);
  const classes = useStyles();
  const ref = useRef<any>(null);

  useEffect(() => {
    if (category) {
      // ref.current?.scroll({ behavior: 'smooth', top: '66px' });
      window.scrollTo(0, ref.current.offsetTop - 85);
    } else {
      window.scrollTo(0, 0);
    }
  }, [category]);

  useEffect(() => {
    const watchScroll = () => {
      window?.addEventListener('scroll', () => setSticky(myFunction()));
    };
    watchScroll();
    // Remove listener (like componentWillUnmount)
    return () => {
      window?.removeEventListener('scroll', () => setSticky(myFunction()));
    };
  }, []);

  return (
    <>
      <Header />
      <div style={{ width: '100%', overflow: 'hidden' }}>
        <img src={'/3.jpg'} style={{ width: '116%', marginLeft: '-8%' }} />
      </div>
      {/* <Banner />*/}

      <div
        ref={ref}
        style={{ transition: 'all 1s cubic-bezier(0.2, 0, 0, 1)' }}
        className={sticky ? classes.sticky : classes.nonSticky}
        id='myHeader'>
        <Category sticky={sticky} />
      </div>

      <div className='content'>{category && <Grade />}</div>
      <img src={'/2.jpg'} style={{ width: '100%' }} />
      {/* <img src={'/1.jpg'} style={{ width: '100%' }} />*/}
      <Footer />
    </>
  );
}
