import { DroppableContainer } from './DroppableContainer';
import React, {
  FC,
  forwardRef,
  ForwardRefRenderFunction,
  Fragment,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { SortableItem1 } from './SortableItem';
import Submission from './Submission';
import { WordBank } from './WordBank';
import { Box, ChakraProvider, Flex, Text } from '@chakra-ui/react';
import {
  closestCorners,
  DndContext,
  DragOverlay,
  KeyboardSensor,
  MouseSensor,
  PointerSensor,
  TouchSensor,
  useDndContext,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { Global } from '@emotion/react';
import { sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import { SolutionGetter, WORD_BANK } from '../../utils';
import shuffle from 'lodash/shuffle';
import ReactHtmlParser from 'react-html-parser';
import { CircularProgress, Grid, Typography } from '@mui/material';
import { SortableItem2 } from '../fillText/SortableItem';
import { Item } from './Item';
import { makeStyles } from '@mui/styles';
import { TTS } from '../Question/Question';

// https://docs.dndkit.com/introduction/getting-started

// Modified the Multiple Containers Grid example on Storybook
// https://5fc05e08a4a65d0021ae0bf2-xkdjvdfnuz.chromatic.com/?path=/docs/presets-sortable-multiple-containers--grid
// code here: https://github.com/clauderic/dnd-kit/blob/b7355d19d9e15bb1972627bb622c2487ddec82ad/stories/2%20-%20Presets/Sortable/MultipleContainers.tsx

// TODO: make custom "coordinates getter" to improve UX of keyboard navigation? -- https://docs.dndkit.com/api-documentation/sensors/keyboard

// need a JSX component that can accept props
export function Blank(solution: any) {
  return <span></span>;
}

const useStyles = makeStyles((theme: any) => ({
  preventSelect: {
    webkitUserSelect: 'none',
    mozUserSelect: 'none',
    msUserSelect: 'none',
    userSelect: 'none',
  },
}));

const mod = 100000007;

const countFreq = (pat: string, txt: string) => {
  const M = pat.length;
  const N = txt.length;
  let res = 0;

  // A loop to slide pat[] one by one
  for (let i = 0; i <= N - M; i++) {
    // For current index i, check for
    // pattern match
    let j;
    for (j = 0; j < M; j++) {
      if (txt[i + j] != pat[j]) {
        break;
      }
    }

    // If pat[0...M-1] = txt[i, i+1, ...i+M-1]
    if (j == M) {
      res++;
      j = 0;
    }
  }
  return res;
};

const transform = (node: any, index: number) => {
  if (node.type === 'text' && node.data.includes('###BLANK###')) {
    return (
      <>
        <>{ReactHtmlParser(node.data.slice(0, node.data.indexOf('###BLANK###')))}</>
        <Blank solution={['1', '2', '3', '4']} />
        <>
          {ReactHtmlParser(
            node.data.slice(node.data.indexOf('###BLANK###') + 11, node.data.length),
            { transform: transform },
          )}
        </>
      </>
    );
  }
};

const textToHtml: any = (x: any, i: number, solutions: string[]) => {
  const y = x?.toString() ?? '';
  if (y.includes('###BLANK###') || y.includes('###BLANK1###')) {
    const n1 = y.indexOf('###BLANK###');
    const n2 = y.indexOf('###BLANK1###');
    if (n1 === -1) {
      return [
        x.slice(0, y.indexOf('###BLANK1###')),
        <Blank key={i} solution={solutions} blankType={2} id={`blank-${i}`} />,
        ...textToHtml(y.slice(y.indexOf('###BLANK1###') + 12, y.length), i + 1, solutions),
      ];
    } else if (n2 === -1) {
      return [
        y.slice(0, y.indexOf('###BLANK###')),
        <Blank key={i} solution={solutions} blankType={1} id={`blank-${i}`} />,
        ...textToHtml(y.slice(y.indexOf('###BLANK###') + 11, y.length), i + 1, solutions),
      ];
    } else {
      return [
        y.slice(0, y.indexOf('###BLANK###')),
        <Blank key={i} solution={solutions} blankType={1} id={`blank-${i}`} />,
        ...textToHtml(y.slice(y.indexOf('###BLANK###') + 11, y.length), i + 1, solutions),
      ];
    }
  } else {
    return [x];
  }
};

const addString = (y: any) => {
  let t = '';
  if (y?.props?.children) {
    for (let i = 0; i < y?.props?.children.length; i++) {
      if (typeof y?.props?.children[i] === 'string') {
        t += y?.props?.children[i];
      } else {
        t += addString(y?.props?.children[i]);
      }
    }
  }
  return t;
};

const simplyfy: any = (x: any, value: number, solutions: string[]) => {
  let m = value;
  if (x?.props?.children) {
    const y = [];
    for (let i = 0; i < x?.props?.children.length; i++) {
      y.push(simplyfy(x?.props?.children?.[i], m, solutions));
      m += countFreq('###BLANK###', addString(x?.props?.children?.[i]));
      m += countFreq('###BLANK1###', addString(x?.props?.children?.[i]));
    }
    if (Array.isArray(y[0])) {
      return {
        ...x,
        props: { ...x.props, children: y.flatMap((e) => e) },
      };
    } else {
      return { ...x, props: { ...x.props, children: y } };
    }
  }
  return textToHtml(x, m, solutions);
};

const parseItemsFromChildren = (
  children: any,
  solutions: any[],
  isTaskComplete?: boolean,
  correctAnswer?: any[],
  xAnswer?: any[],
) => {
  console.log(children);
  const solutionGetter = new SolutionGetter();
  let value = 0;
  const children1 = [];
  const s = [];
  const l = [];

  for (const x of xAnswer ?? []) {
    const text = x;
    if (document.getElementById('text101') !== null) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      document.getElementById('text101').innerHTML = text;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      l.push(document.getElementById('text101').getClientRects()[0].width + 1);
    }
  }
  for (const sElement of solutions) {
    const text = sElement.value;
    if (document.getElementById('text101') !== null) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      document.getElementById('text101').innerHTML = text;
      s.push({
        ...sElement,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        width: document.getElementById('text101').getClientRects()[0].width + 1,
      });
    }
  }
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  document.getElementById('text101').innerHTML = '';

  for (let i = 0; i < children.length; i++) {
    children1.push(simplyfy(children?.[i], value, s));
    value += countFreq('###BLANK###', addString(children?.[i]));
    value += countFreq('###BLANK1###', addString(children?.[i]));
  }
  const childrenWithBlanks = React.Children.toArray(children1).map((child: any, index) => {
    return child;
  });

  const blanks: any = childrenWithBlanks.reduce((acc: any, currChild) => {
    if (currChild.solutions) {
      return {
        ...acc,
        [currChild.id]: currChild,
      };
    }

    return acc;
  }, {});

  blanks[WORD_BANK] = {
    items: shuffle(solutions),
    showingItems: shuffle(solutions),
  };
  for (let i = 0; i < value; i++) {
    blanks[`blank-${i}`] = {
      id: `blank-${i}`,
      number: i,
      solutions,
      isCorrect: isTaskComplete || null,
      items: correctAnswer?.[i] ?? [],
    };
  }
  const fillMaxWidth = l.sort((a: any, b: any) => b - a)?.[0];
  return [blanks, childrenWithBlanks, fillMaxWidth];
};

const correctParseItemsFromChildren1 = (children: any, solutions: any[], xAnswer?: any[]) => {
  let value = 0;
  const children1 = [];
  for (let i = 0; i < children.length; i++) {
    children1.push(simplyfy(children?.[i], value, solutions));
    value += countFreq('###BLANK###', addString(children?.[i]));
    value += countFreq('###BLANK1###', addString(children?.[i]));
  }
  const childrenWithBlanks = React.Children.toArray(children1).map((child: any, index) => {
    return child;
  });
  const l = [];

  for (const x of solutions ?? []) {
    const text = x;
    if (document.getElementById('text101') !== null) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      document.getElementById('text101').innerHTML = text;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      l.push(document.getElementById('text101').getClientRects()[0].width + 1);
    }
  }

  if (document.getElementById('text101') !== null) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    document.getElementById('text101').innerHTML = '';
  }

  const blanks: any = childrenWithBlanks.reduce((acc: any, currChild) => {
    if (currChild.solutions) {
      return {
        ...acc,
        [currChild.id]: currChild,
      };
    }

    return acc;
  }, {});

  for (let i = 0; i < solutions.length; i++) {
    blanks[`blank-${i}`] = {
      id: `blank-${i}`,
      number: i,
      solutions,
      isCorrect: true,
      items: solutions[i],
    };
  }

  blanks[WORD_BANK] = {
    items: shuffle(solutions),
    showingItems: shuffle(solutions),
  };
  const fillMaxWidth = l.sort((a: any, b: any) => b - a)?.[0];
  return [blanks, childrenWithBlanks, fillMaxWidth];
};

interface DndI {
  taskId: string;
  number: number;
  answers: any[];
  correctAnswer: string[] | undefined;
  dAnswers: undefined | string[];
  setCorrectAnswer: (x: any) => void;
  children?: any;
  successMessage?: string;
  title?: string;
  failureMessage?: string;
  items?: object;
  additionalAnswers?: number;
  haveDrag?: boolean;
  xAnswer?: string[];
  lable1?: string;
  lable2?: string;
  questionImage?: string;
  questionHead?: string;
}

interface CorrectDndI {
  children?: any;
  answers?: any[];
  xAnswer?: string[];
}

interface CallBlankI {
  child: any;
  index: number | string;
  items: any;
  setCorrectAnswer: (x: any) => void;
  setCanswers: (x: any) => void;
  correctAnswer: string[] | undefined;
  isMapRemove: (x: string) => void;
  hasSubmitted?: boolean;
  disabled: boolean;
  haveDrag?: boolean;
  fillMaxWidth?: number;
}

const CallBlank: FC<CallBlankI> = ({
  child,
  index,
  items,
  setCorrectAnswer,
  setCanswers,
  correctAnswer,
  isMapRemove,
  hasSubmitted,
  disabled,
  haveDrag,
  fillMaxWidth,
}) => {
  const [props] = useState(child.props);

  if (props?.children) {
    const x = props.children.map((child1: any, index1: number) => {
      return (
        <CallBlank
          key={index1 + '-' + index}
          child={child1}
          index={index1 + '-' + index}
          items={items}
          setCorrectAnswer={setCorrectAnswer}
          setCanswers={setCanswers}
          correctAnswer={correctAnswer}
          isMapRemove={isMapRemove}
          hasSubmitted={hasSubmitted}
          disabled={disabled}
          haveDrag={haveDrag}
          fillMaxWidth={fillMaxWidth}
        />
      );
    });

    return { ...child, props: { ...child.props, children: x } };
  }

  if (props?.solution && items?.[props?.id]) {
    const minW = props?.solution?.sort((a: any, b: any) => b.width - a.width)?.[0]?.width ?? 40;
    const { items: blankItems, isCorrect: isBlankCorrect } = items[props?.id];
    if (props.blankType === 1) {
      return (
        <>
          <DroppableContainer
            key={props.id}
            id={props.id}
            items={Array.isArray(blankItems) ? blankItems : [blankItems]}
            isCorrect={isBlankCorrect}
            allBlanksEmpty={false}
            minW={minW + 54}
            style={{
              height: '40px',
            }}>
            {(Array.isArray(blankItems) ? blankItems : [{ value: blankItems, id: 0 }]).map(
              (value: any, i: number) => {
                if (typeof value === 'object') {
                  return (
                    <SortableItem1
                      value={value?.value ?? ''}
                      isMap={items?.[props?.id].items.length > 0}
                      isMapRemove={() => isMapRemove(props?.id)}
                      key={`sortable-item--${value}`}
                      id={value?.id ?? i}
                      taskId={i + ''}
                      isCorrect={isBlankCorrect}
                      hasSubmitted={hasSubmitted}
                    />
                  );
                } else {
                  return (
                    <SortableItem1
                      value={value ?? ''}
                      isMap={items?.[props?.id].items.length > 0}
                      isMapRemove={() => isMapRemove(props?.id)}
                      key={`sortable-item--${value}`}
                      id={value + i}
                      taskId={i + ''}
                      isCorrect={isBlankCorrect}
                      hasSubmitted={hasSubmitted}
                    />
                  );
                }
              },
            )}
          </DroppableContainer>
        </>
      );
    } else {
      return (
        <>
          <div
            key={props.id}
            id={props.id}
            style={{
              height: '40px',
              maxWidth: `${(fillMaxWidth ?? 25) + 69}px`,
            }}>
            {[blankItems].map((value: string, i) => {
              return (
                <SortableItem2
                  isMap={items?.[props?.id].items.length > 0}
                  isMapRemove={() => isMapRemove(props?.id)}
                  key={`sortable-item--${props?.id}`}
                  setValue={(x) => {
                    setCanswers(
                      correctAnswer?.map((e: string, i: number) =>
                        i === items[props?.id].number ? x : e,
                      ),
                    );
                    setCorrectAnswer(
                      correctAnswer?.map((e: string, i: number) =>
                        i === items[props?.id].number ? x : e,
                      ),
                    );
                  }}
                  value={correctAnswer?.[items[props?.id].number] ?? ''}
                  id={props?.id}
                  taskId={i + ''}
                  isCorrect={isBlankCorrect}
                  hasSubmitted={hasSubmitted}
                  disabled={disabled}
                  haveDrag={haveDrag && 94 <= (fillMaxWidth ?? 25) + 59}
                />
              );
            })}
          </div>
        </>
      );
    }
  }
  return <Fragment key={index}>{child}</Fragment>;
};

const Dnd1: ForwardRefRenderFunction<
  {
    submit: (answers: string[], dAnswers: undefined | string[]) => void;
    getAnswer: () => string[];
  },
  DndI
> = (
  {
    taskId,
    answers,
    dAnswers,
    setCorrectAnswer,
    correctAnswer,
    children,
    additionalAnswers,
    title = 'Drag and Drop',
    successMessage = 'Nicely done!',
    failureMessage = 'read the course to find the right information.',
    haveDrag,
    xAnswer,
    lable1,
    lable2,
    questionImage,
    questionHead,
    number,
  },
  ref,
) => {
  const classes = useStyles();
  const [isCorrect, setIsCorrect] = useState(false);
  const [loading, setLoading] = useState(false);
  const [activeId, setActiveId] = useState<string | null>(null);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [canswers, setCanswers] = useState(dAnswers ?? answers.map(() => ''));
  const [isDragging, setIsDragging] = useState(false);
  const submitRef = useRef<any>();
  const contex = useDndContext();

  useEffect(() => {
    if ((additionalAnswers ?? 0) > 0 && answers.length === canswers.length) {
      setCanswers([...answers.map(() => ''), ...[...new Array(additionalAnswers)].map(() => '')]);
      console.log([...answers.map(() => ''), ...[...new Array(additionalAnswers)].map(() => '')]);
    }
  }, [additionalAnswers, answers, setCanswers, canswers]);

  useImperativeHandle(
    ref,
    () => ({
      submit(answers: string[], dxAnswers: undefined | string[]) {
        submitRef.current?.submit(answers, dxAnswers);
      },
      getAnswer() {
        return canswers;
      },
    }),
    [canswers],
  );

  const [defaultItems, childrenWithBlanks, fillMaxWidth] = useMemo(() => {
    return parseItemsFromChildren(children, answers, hasSubmitted, correctAnswer, xAnswer);
    // if (hasSubmitted) {
    //   submitRef.current.submit();
    // }
  }, [hasSubmitted]);

  // keys in `items` are the ids of the blanks/droppableContainers
  const [items, setItems] = useState<any>(defaultItems);
  const allBlanksEmpty = useMemo(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    () => !Object.entries(items).some(([key, value]) => key !== WORD_BANK && value.items.length),
    [items],
  );

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(MouseSensor),
    useSensor(TouchSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  // find the blank/droppableContainer that an item is in
  const findContainer = useCallback(
    (id: string) => {
      if (id in items) {
        return id;
      }

      return Object.keys(items).find(
        (key) =>
          typeof items[key].items === 'object' &&
          items[key].items.find((e: { value: string; id: string }) => e.id + '' === id),
      );
    },
    [items],
  );

  const onDragStart = useCallback(
    (val: { active: any; over: any }) => {
      setIsDragging(true);
      if (!hasSubmitted) {
        setActiveId(answers?.[+val.active.id].value);
      }
    },
    [hasSubmitted],
  );

  const onDragEnd = useCallback(
    (val: { active: any; over: any }) => {
      setIsDragging(false);
      if (!hasSubmitted) {
        if (!dAnswers) {
          const { active, over } = val;
          const activeContainer = findContainer(active.id);

          if (!activeContainer) {
            setActiveId(null);
            return;
          }
          // const overId = over?.id;
          const c: any[] = Object.values(items).filter((blank: any) => blank.items.length === 0);
          let overId =
            typeof over?.id === 'string' ? over?.id : 'blank-' + (Object.keys(items).length - 2);

          if (Array.isArray(over?.id) && over.id.length === 0 && c.length !== 0) {
            overId = c[0].id;
          }
          const overContainer = findContainer(overId);

          if (activeContainer && overContainer) {
            const activeIndex =
              activeContainer === WORD_BANK
                ? items[activeContainer].showingItems.findIndex((e: any) => e.id + '' === active.id)
                : items[activeContainer].items.indexOf(active.id);
            const overIndex =
              overContainer === WORD_BANK
                ? items[overContainer].showingItems.findIndex((e: any) => e.id + '' === overId)
                : items[overContainer].items.indexOf(overId);

            // if it's different than overContainer, swap the items
            if (activeContainer !== overContainer) {
              const activeItems =
                activeContainer === WORD_BANK
                  ? [...items[activeContainer].showingItems]
                  : [...items[activeContainer].items];
              let overItems =
                overContainer === WORD_BANK
                  ? [...items[overContainer].showingItems]
                  : [...items[overContainer].items];
              // activeContainer gets what was in overContainer and vice versa
              // first check if overContainer is word bank or a blank
              // if it's a blank (NOT the word bank), swap contents with activeContainer
              // if it IS word bank, just move activeContainer contents to word bank
              if (overContainer !== WORD_BANK) {
                const x = { ...activeItems[activeIndex] };
                if (activeItems[activeIndex]?.id + '' === active.id) {
                  activeItems.splice(activeIndex, 1);

                  // if there's already something in the blank, push its contents to activeItems
                  if (overItems.length) {
                    activeItems.push(...overItems);
                  }
                  overItems = [x];
                }
              }

              let updatedItems = { ...items };

              if (activeContainer === WORD_BANK) {
                if (canswers[updatedItems[overContainer].number] === '') {
                  setCanswers(
                    canswers.map((e: string, i: number) => {
                      if (i === updatedItems[overContainer].number) {
                        return overItems[0].value;
                      }
                      return e;
                    }),
                  );
                  setCorrectAnswer(
                    canswers.map((e: string, i: number) => {
                      if (i === updatedItems[overContainer].number) {
                        return overItems[0].value;
                      }
                      return e;
                    }),
                  );
                  updatedItems = {
                    ...items,
                    [activeContainer]: {
                      ...items[activeContainer],
                      id: activeContainer,
                      isCorrect: null,
                      showingItems: activeItems,
                    },
                    [overContainer]: {
                      ...items[overContainer],
                      id: overContainer,
                      isCorrect: null,
                      items: overItems,
                    },
                  };
                }
              }
              // else if (overContainer === WORD_BANK) {
              //   updatedItems = {
              //     ...items,
              //     [activeContainer]: {
              //       ...items[activeContainer],
              //       id: activeContainer,
              //       isCorrect: null,
              //       items: activeItems,
              //     },
              //     [overContainer]: {
              //       ...items[overContainer],
              //       id: overContainer,
              //       isCorrect: null,
              //       showingItems: activeItems,
              //     },
              //   };
              // } else {
              //   updatedItems = {
              //     ...items,
              //     [activeContainer]: {
              //       ...items[activeContainer],
              //       id: activeContainer,
              //       isCorrect: null,
              //       items: activeItems,
              //     },
              //     [overContainer]: {
              //       ...items[overContainer],
              //       id: overContainer,
              //       isCorrect: null,
              //       items: overItems,
              //     },
              //   };
              // }

              // reset isCorrect values if all of the blanks (minus word bank) are empty
              if (allBlanksEmpty) {
                Object.values(updatedItems).forEach((blank: any) => {
                  blank.isCorrect = null;
                });
              }
              setItems(updatedItems);
            }
            // else if (activeIndex !== overIndex) {
            //   const updatedItems = {
            //     ...items,
            //     [overContainer]: {
            //       ...items[overContainer],
            //       id: overContainer,
            //       isCorrect: null,
            //       items: arrayMove(items[overContainer].items, activeIndex, overIndex),
            //     },
            //   };
            //   setItems(updatedItems);
            // }
          }
          setActiveId(null);
        }
      }
    },
    [items, dAnswers, canswers, hasSubmitted],
  );

  const onDragCancel = useCallback(() => {
    setIsDragging(false);
    if (!hasSubmitted) {
      setActiveId(null);
    }
  }, [hasSubmitted]);

  const isMapRemove = useCallback(
    (x: string) => {
      // setLoading(true);
      const updatedItems = { ...items };
      setCanswers(
        canswers.map((e: string, i: number) => {
          if (i === updatedItems[x].number) {
            return '';
          }
          return e;
        }),
      );
      setCorrectAnswer(
        canswers.map((e: string, i: number) => {
          if (i === updatedItems[x].number) {
            return '';
          }
          return e;
        }),
      );
      setItems({
        ...items,
        WORD_BANK: {
          items: items?.[WORD_BANK]?.items ?? [],
          showingItems: [...(items?.[WORD_BANK]?.showingItems ?? []), ...(items?.[x]?.items ?? [])],
          isCorrect: null,
        },
        [x]: {
          ...items?.[x],
          id: x,
          items: [],
        },
      });

      // setTimeout(() => {
      //   // setLoading(false);
      // }, 1200);
    },
    [items, setItems],
  );

  const [colorScheme] = useState(!hasSubmitted ? 'blue' : isCorrect ? 'green' : 'red');
  const [showWordBank] = useState(!hasSubmitted || !isCorrect);

  return (
    <Box py='1' maxW='960px' sx={{ width: '100%' }}>
      <Flex align='center' color={`${colorScheme}.600`} fontWeight='semibold' mb='2'>
        <Text>{title}</Text>
      </Flex>
      <ChakraProvider>
        {!loading ? (
          <DndContext
            sensors={sensors}
            collisionDetection={closestCorners}
            onDragStart={onDragStart}
            onDragEnd={onDragEnd}
            onDragCancel={onDragCancel}>
            <Flex direction='column' alignItems='flex-start' className={classes.preventSelect}>
              <div style={{ display: 'flex', margin: '20px 0', alignItems: 'flex-start' }}>
                <Typography variant='h6' sx={{ fontWeight: 600, fontSize: '16px' }}>
                  {number})
                </Typography>
                <div style={{ flex: 1, paddingLeft: '10px' }}>
                  {!!questionHead && questionHead !== '' && (
                    <TTS>
                      <Typography style={{ fontSize: '15px', margin: '0px !important' }}>
                        {ReactHtmlParser(questionHead)}
                      </Typography>
                    </TTS>
                  )}
                  {lable1 && (
                    <div>
                      <h5
                        color={'primary'}
                        style={{ marginBottom: '20px', marginTop: '8px', fontSize: '12px' }}>
                        {lable1}
                      </h5>
                    </div>
                  )}
                  {!!questionImage && questionImage !== '' && (
                    <img
                      src={process.env.REACT_APP_File_URL + questionImage}
                      alt='My Team'
                      style={{
                        maxWidth: '90%',
                        maxHeight: '46vh',
                        marginLeft: '50px',
                      }}
                    />
                  )}
                  {lable2 && (
                    <div>
                      <h5
                        color={'primary'}
                        style={{ marginBottom: '20px', marginTop: '8px', fontSize: '12px' }}>
                        {lable2}
                      </h5>
                    </div>
                  )}
                  <div style={{ display: 'grid' }}>
                    {childrenWithBlanks.map((child: any, index: number) => (
                      <CallBlank
                        key={index}
                        child={child}
                        index={index}
                        items={items}
                        setCorrectAnswer={setCorrectAnswer}
                        correctAnswer={correctAnswer}
                        setCanswers={setCanswers}
                        isMapRemove={
                          !hasSubmitted
                            ? isMapRemove
                            : (x) => {
                                return;
                              }
                        }
                        hasSubmitted={hasSubmitted}
                        disabled={!!dAnswers}
                        haveDrag={haveDrag}
                        fillMaxWidth={fillMaxWidth}
                      />
                    ))}
                  </div>
                  {showWordBank && haveDrag && <WordBank taskId={taskId} items={items} minW={40} />}
                </div>
              </div>
            </Flex>
            <DragOverlay style={{ height: '32px' }}>
              {activeId && (
                <div style={{ marginTop: '100px', marginRight: '34px' }}>
                  <Global styles={{ body: { cursor: 'grabbing' } }} />
                  {isDragging && <Item value={activeId} />}
                </div>
              )}
            </DragOverlay>
            <Submission
              ref={submitRef}
              taskId={taskId}
              isCorrect={isCorrect}
              items={items}
              hasSubmitted={hasSubmitted}
              failureMessage={failureMessage}
              successMessage={successMessage}
              setIsCorrect={setIsCorrect}
              setItems={setItems}
              setCorrectAnswer={setCorrectAnswer}
              correctAnswer={correctAnswer}
              setCanswers={setCanswers}
              canswers={canswers}
              // reset={onDragCancel}
              initialItems={defaultItems}
              setHasSubmitted={setHasSubmitted}
            />
          </DndContext>
        ) : (
          <div
            style={{
              width: '100%',
              height: '100%',
              padding: '100px',
              display: 'flex',
              justifyContent: 'center',
            }}>
            <CircularProgress />
          </div>
        )}
      </ChakraProvider>
    </Box>
  );
};

export const Dnd = forwardRef<
  {
    submit: (answers: string[], dAnswers: undefined | string[]) => void;
    getAnswer: () => string[];
  },
  DndI
>(Dnd1);

export const CorrectDnd: FC<CorrectDndI> = ({ children, answers = [], xAnswer }) => {
  const [defaultItems, childrenWithBlanks, fillMaxWidth] = useMemo(
    () => correctParseItemsFromChildren1(children, answers, xAnswer),
    [children, answers, xAnswer],
  );
  // keys in `items` are the ids of the blanks/droppableContainers
  const [items, setItems] = useState<any>(defaultItems);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  return (
    <Box py='1' maxW='960px' sx={{ width: '100%' }}>
      <ChakraProvider>
        <DndContext
          sensors={sensors}
          collisionDetection={closestCorners}
          onDragStart={() => {
            //
          }}
          onDragEnd={() => {
            //
          }}
          onDragCancel={() => {
            //
          }}>
          <Flex direction='column' alignItems='flex-start'>
            <div>
              {childrenWithBlanks.map((child: any, index: number) => (
                <CallBlank
                  key={index}
                  child={child}
                  index={index}
                  items={items}
                  setCorrectAnswer={(e) => console.log(e)}
                  setCanswers={(e) => console.log(e)}
                  correctAnswer={answers}
                  isMapRemove={(x: string) => console.log(x)}
                  disabled={true}
                  fillMaxWidth={fillMaxWidth}
                />
              ))}
            </div>
          </Flex>
        </DndContext>
      </ChakraProvider>
    </Box>
  );
};
