import React, { FC } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { useDroppable } from '@dnd-kit/core';

interface DroppableContainer {
  children: any;
  id: string;
  items: string[];
  minW: number;
  isCorrect?: boolean;
  allBlanksEmpty?: boolean;
  style?: object;
}

export const DroppableContainer: FC<DroppableContainer> = ({
  children,
  id,
  items,
  isCorrect,
  allBlanksEmpty,
  style,
  minW,
}) => {
  const { over, isOver, setNodeRef } = useDroppable({
    id,
  });
  const isOverContainer = isOver || (over ? items.includes(over.id) : false);

  return (
    <Box
      ref={setNodeRef}
      display='inline-block'
      minW={`${minW}px`}
      minH='40px'
      p='2px'
      my='1'
      borderWidth='2px'
      rounded='md'
      transition='background-color .35s ease'
      sx={style}
      css={(themes: any) => {
        if (isOverContainer) {
          return {
            backgroundColor: themes.theme.colors.gray[100],
          };
        }

        if (!allBlanksEmpty && typeof isCorrect === 'boolean') {
          return {
            backgroundColor: themes.theme.colors[isCorrect ? 'green' : 'red'][100],
          };
        }

        // if (!allBlanksEmpty) {
        //   return {
        //     backgroundColor: themes.theme.colors[isCorrect ? 'green' : 'red'][100],
        //   };
        // }
      }}>
      {children.length ? (
        children
      ) : (
        <Flex align='center' h='full'>
          &nbsp;
        </Flex>
      )}
    </Box>
  );
};
