import {
  Avatar,
  Typography,
  Container,
  Grid,
  Button,
  TextField,
  Link,
  FormControl,
  OutlinedInput, InputLabel, InputAdornment, IconButton, FormHelperText,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { FC, useCallback, useEffect, useState } from 'react';
import Header from '../components/Header/header';
import Footer from '../components/Footer/Footer';
import { useNavigate } from 'react-router-dom';
import { useConnection, useUserInfo } from '../connection/Application';
import Swal from 'sweetalert2';
import { Visibility, VisibilityOff } from '@mui/icons-material';

const useStyles = makeStyles((theme: any) => ({
  paper: {
    paddingTop: '15vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

interface SignInProp {
  setIsAuthenticated: any;
}

const Login: FC<SignInProp> = ({ setIsAuthenticated = (x: any) => console.log(x) }) => {
  const classes = useStyles();
  const connection = useConnection();
  const navigator = useNavigate();
  const user = useUserInfo();
  const [loading, setLoading] = useState<boolean>(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const [userData, setUserData] = useState<{
    email: string;
    password: string;
  }>({
    email: '',
    password: '',
  });

  const [error, setError] = useState<{
    email: string;
    password: string;
  }>({
    email: '',
    password: '',
  });

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  useEffect(() => {
    if (user) {
      navigator('/');
    }
  }, [user]);

  const login = useCallback(() => {
    setLoading(true);
    const error1 = {
      email: '',
      password: '',
    };
    if (userData.email === '') {
      error1.email = 'Email is required';
      // eslint-disable-next-line
    } else if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(userData.email)) {
      error1.email = 'Email is not valid';
    }
    if (userData.password === '') {
      error1.password = 'Password is required';
    } else if (
      !/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/.test(userData.password)
    ) {
      error1.password = 'Password is not valid';
    }
    setError(error1);
    if (error1.email === '' && error1.password === '') {
      connection
        .post('users/session', { userName: userData.email, password: userData.password, type: 1 })
        .then((response: any) => {
          connection.setToken(
            response.response.token.token,
            response.response.token.refreshToken.token,
          );
          setIsAuthenticated(true);
          navigator('/');
        })
        .catch((e) => {
          Swal.fire({
            title: 'Error!',
            text: e.message,
            icon: 'error',
            confirmButtonText: 'Ok',
          });
        })
        .finally(() => setLoading(false));
    } else {
      setLoading(false);
    }
  }, [userData]);

  return (
    <>
      <Container component='main' maxWidth='xs' sx={{ minHeight: 'calc( 100vh - 128px)' }}>
        {/* <CssBaseline />*/}
        <Header />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}></Avatar>
          <Typography component='h1' variant='h5'>
            Sign In
          </Typography>
          <div className={classes.form}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  variant='outlined'
                  required
                  fullWidth
                  id='email'
                  label='Email Address'
                  name='email'
                  autoComplete='email'
                  value={userData.email}
                  helperText={error.email}
                  onChange={(e) => setUserData({ ...userData, email: e.target.value })}
                  FormHelperTextProps={{ style: { color: '#E53935' } }}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl style={{ width: '100%' }}>
                  <InputLabel htmlFor="password">Password</InputLabel>
                  <OutlinedInput
                    required
                    autoComplete='current-password'
                    value={userData.password}
                    // helperText={error.password}
                    onChange={(e) => setUserData({ ...userData, password: e.target.value })}
                    // FormHelperTextProps={{ style: { color: '#E53935' } }}
                    type={showPassword ? 'text' : 'password'}
                    name="password"
                    id="password"
                    aria-label={'ccc'}
                    color={'primary'}
                    label="Password"
                    fullWidth
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end">
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  {!!error.password && (
                    <FormHelperText error id="password">
                      {error.password}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              {/* <Grid item xs={12}>*/}
              {/*  <FormControlLabel*/}
              {/*    control={<Checkbox value='allowExtraEmails' color='primary' />}*/}
              {/*    label='I want to receive inspiration, marketing promotions and updates via email.'*/}
              {/*  />*/}
              {/* </Grid>*/}
            </Grid>
            <Button
              type='submit'
              fullWidth
              variant='contained'
              color='primary'
              onClick={() => login()}
              disabled={loading}
              sx={{ marginTop: 3, marginBottom: 3 }}
              className={classes.submit}>
              Sign In
            </Button>
            <Grid>
              <Grid item>
                <Link href='/signup' variant='body2'>
                  {/* eslint-disable-next-line react/no-unescaped-entities */}
                  Don't have an account yet? Sign Up
                </Link>
              </Grid>
              <Grid item>
                <Link href='/forget-password' variant='body2'>
                  Forget Password
                </Link>
              </Grid>
            </Grid>
          </div>
        </div>
      </Container>
      <Footer />
    </>
  );
};

export default Login;
