import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
  ForwardRefRenderFunction,
} from 'react';
import { makeStyles } from '@mui/styles';
import { usePageVisibility } from 'react-page-visibility';
import './Time.css';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const useStyle = makeStyles({
  timerDisplayContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '180px',
    // eslint-disable-next-line
    backgroundImage: "url('https://assets.ccbp.in/frontend/react-js/digital-timer-elapsed-bg.png')",
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    ['@media (min-width: 576px)']: {
      // eslint-disable-line no-useless-computed-key
      height: '320px',
    },
    ['@media (min-width: 768px)']: {
      // eslint-disable-line no-useless-computed-key
      height: '450px',
    },
    ['@media (min-width: 992px)']: {
      // eslint-disable-line no-useless-computed-key
      height: '600px',
    },
  },
});

type TimerType = {
  mark: number;
  getSeconds: (y: number) => void;
};

const Timer1: ForwardRefRenderFunction<
  {
    toggle: (x: boolean) => void;
    reset: () => void;
  },
  TimerType
> = (props, ref) => {
  const [seconds, setSeconds] = useState(0);
  const [isActive, setIsActive] = useState(false);
  const classes = useStyle();
  const isVisible = usePageVisibility();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  useImperativeHandle(
    ref,
    () => ({
      toggle(x) {
        setIsActive(x);
      },

      reset() {
        setSeconds(0);
        setIsActive(false);
      },
    }),
    [seconds],
  );

  useEffect(() => {
    let interval: any = null;
    if (isActive) {
      interval = setInterval(() => {
        props.getSeconds(isVisible ? seconds + 1 : seconds);
        setSeconds((seconds) => (isVisible ? seconds + 1 : seconds));
      }, 1000);
    } else if (!isActive && seconds !== 0) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [isActive, seconds, isVisible]);

  return (
    <div className={classes.timerDisplayContainer}>
      <div style={{ display: 'flex', marginBottom: matches ? '15px' : '50px' }}>
        <div className='time-container time-hours'>
          <span className='time-value time-hours-value'>
            {String(Math.floor(seconds / 3600)).padStart(2, '0')}
          </span>
          <span className='time-label time-hours-label'>Hours</span>
        </div>
        <div className='time-container time-minutes'>
          <span className='time-value time-minutes-value'>
            {String(Math.floor((seconds % 3600) / 60)).padStart(2, '0')}
          </span>
          <span className='time-label time-minutes-label'>Minutes</span>
        </div>
        <div className='time-container time-seconds'>
          <span className='time-value time-seconds-value'>
            {String(seconds % 60).padStart(2, '0')}
          </span>
          <span className='time-label time-seconds-label'>Seconds</span>
        </div>
      </div>
      <div className='time-container time-minutes'>
        <span className='time-value time-minutes-value'>{props.mark}</span>
        <span className='time-label time-minutes-label'>Marks</span>
      </div>
      {/* <div className='row'>*/}
      {/*  <button*/}
      {/*    className={`button button-primary button-primary-${isActive ? 'active' : 'inactive'}`}*/}
      {/*    onClick={toggle}>*/}
      {/*    {isActive ? 'Pause' : 'Start'}*/}
      {/*  </button>*/}
      {/*  <button className='button' onClick={reset}>*/}
      {/*    Reset*/}
      {/*  </button>*/}
      {/* </div>*/}
    </div>
  );
};

const Timer = forwardRef<
  {
    toggle: (x: boolean) => void;
    reset: () => void;
  },
  TimerType
>(Timer1);
export default Timer;
