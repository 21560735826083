import React from 'react';
import { Box, Typography, Link } from '@mui/material';
import useStyles from '../../styles/styles';
import { Copyright } from '@mui/icons-material';

const Footer = () => {
  const date = new Date().getFullYear();
  const classes = useStyles();

  return (
    <Box sx={{ flexGrow: 1 }} className={classes.footerContainer}>
      My Home Tutor <Copyright /> MyHomeTutor
    </Box>
  );
};

export default Footer;
