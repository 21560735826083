import React, { FC, useCallback, useEffect, useState } from 'react';
import { useConnection, useUserInfo } from '../connection/Application';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { Avatar, Button, Container, Grid, Link, TextField, Typography } from '@mui/material';
import Header from '../components/Header/header';
import Footer from '../components/Footer/Footer';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: any) => ({
  paper: {
    paddingTop: '15vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const RequestPasswordRest: FC = () => {
  const classes = useStyles();
  const connection = useConnection();
  const navigator = useNavigate();
  const user = useUserInfo();
  const [loading, setLoading] = useState<boolean>(false);
  const [userData, setUserData] = useState<{
    email: string;
  }>({
    email: '',
  });

  const [error, setError] = useState<{
    email: string;
  }>({
    email: '',
  });

  useEffect(() => {
    if (user) {
      navigator('/');
    }
  }, [user]);

  const requestPasswordRest = useCallback(() => {
    setLoading(true);
    const error1 = {
      email: '',
      password: '',
    };
    if (userData.email === '') {
      error1.email = 'Email is required';
      // eslint-disable-next-line
    } else if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(userData.email)) {
      error1.email = 'Email is not valid';
    }
    setError(error1);
    if (error1.email === '' && error1.password === '') {
      connection
        .patch('users/user/password', { userName: userData.email })
        .then((response: any) => {
          navigator(`/password-reset?email=${userData.email}`);
        })
        .catch(() => {
          Swal.fire({
            title: 'Error!',
            text: 'User not Found.',
            icon: 'error',
            confirmButtonText: 'Ok',
          });
        })
        .finally(() => setLoading(false));
    } else {
      setLoading(false);
    }
  }, [userData]);

  return (
    <>
      <Container component='main' maxWidth='xs' sx={{ minHeight: 'calc( 100vh - 128px)' }}>
        {/* <CssBaseline />*/}
        <Header />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}></Avatar>
          <Typography component='h1' variant='h5'>
            Forget Password
          </Typography>
          <div className={classes.form}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  variant='outlined'
                  required
                  fullWidth
                  id='email'
                  label='Email Address'
                  name='email'
                  autoComplete='email'
                  value={userData.email}
                  helperText={error.email}
                  onChange={(e) => setUserData({ ...userData, email: e.target.value })}
                  FormHelperTextProps={{ style: { color: '#E53935' } }}
                />
              </Grid>
              {/* <Grid item xs={12}>*/}
              {/*  <FormControlLabel*/}
              {/*    control={<Checkbox value='allowExtraEmails' color='primary' />}*/}
              {/*    label='I want to receive inspiration, marketing promotions and updates via email.'*/}
              {/*  />*/}
              {/* </Grid>*/}
            </Grid>
            <Button
              type='submit'
              fullWidth
              variant='contained'
              color='primary'
              onClick={() => requestPasswordRest()}
              disabled={loading}
              sx={{ marginTop: 3, marginBottom: 3 }}
              className={classes.submit}>
              Request For Password Rest
            </Button>
            <Grid>
              <Grid item>
                <Link href='/login' variant='body2'>
                  {/* eslint-disable-next-line react/no-unescaped-entities */}
                  Login
                </Link>
              </Grid>
              <Grid item>
                <Link href='/signup' variant='body2'>
                  {/* eslint-disable-next-line react/no-unescaped-entities */}
                  Don't have an account yet? Sign Up
                </Link>
              </Grid>
            </Grid>
          </div>
        </div>
      </Container>
      <Footer />
    </>
  );
};

export default RequestPasswordRest;
