import React, { FC } from 'react';
import { Item } from './Item';
import { useSortable } from '@dnd-kit/sortable';

const SORTABLE_TRANSITION_DURATION = 250;

interface SortableItemI {
  taskId: string;
  isMap: boolean;
  id: number;
  value: string;
  isMapRemove?: () => void;
  hasSubmitted?: boolean;
  isCorrect?: boolean;
}

export const SortableItem1: FC<SortableItemI> = ({
  id,
  value,
  isMap,
  taskId,
  isMapRemove,
  isCorrect,
  hasSubmitted,
}) => {
  const { setNodeRef, listeners, isDragging, transform, transition } = useSortable({
    id: id + '',
    transition: {
      duration: SORTABLE_TRANSITION_DURATION,
      easing: 'ease',
    },
  });

  return (
    <Item
      ref={setNodeRef}
      isMap={isMap}
      isMapRemove={isMapRemove}
      dragging={isDragging}
      transition={transition}
      transform={transform}
      listeners={listeners}
      isCorrect={isCorrect}
      hasSubmitted={hasSubmitted}
      value={value}
    />
  );
};
