function menuItem(theme: any) {
  const { palette, borders, transitions } = theme;

  const { secondary, light, dark } = palette;

  return {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    color: '#FF4',
    borderRadius: '50%',
    transition: transitions.create('background-color', {
      easing: transitions.easing.easeInOut,
      duration: transitions.duration.standard,
    }),

    '& *': {
      transition: 'color 100ms linear',
    },

    '&:not(:last-child)': {
      mb: 1,
    },

    '&:hover': {
      backgroundColor: 'white',

      '& *': {
        color: 'black',
      },
    },
  };
}

export default menuItem;
