import { useContext } from 'react';
import ApplicationContext, { ApiType as ContextApiType } from './ApplicationContext';

export type ApiType = ContextApiType;

export const useConnection = () => {
  const context = useContext(ApplicationContext);
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return context.connection!;
};

export const useUserInfo = () => {
  const context = useContext(ApplicationContext);
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return context.user;
};
