import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import UniversalCookie from 'universal-cookie';
import config from '../config';
import superagent from 'superagent';
import { useNavigate } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import { CookiesProvider } from 'react-cookie';
import events from 'events';
export const AUTH_TOKEN = 'auth-token';
export const REFRESH_TOKEN = 'refresh-token';
export type RequestMessage = { [key: string]: any };
export type RequestHeader = { [key: string]: string };
export type ApiType = 'put' | 'get' | 'post' | 'patch' | 'delete';

import { CookieSetOptions } from 'universal-cookie/cjs/types';

export const parseJwt = (token: string) => {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
};
let tokenChanging = false;
let requestQue: { promiseResolve: any; promiseReject: any }[] = [];

export type ConnectionApi = {
  send: <T = any>(
    api: ApiType,
    path: string,
    msg?: RequestMessage,
    params?: RequestMessage,
    headers?: any,
  ) => Promise<T>;
  post: <T = any>(
    path: string,
    msg?: RequestMessage,
    params?: RequestMessage,
    headers?: any,
  ) => Promise<T>;
  put: <T = any>(
    path: string,
    msg?: RequestMessage,
    params?: RequestMessage,
    headers?: any,
  ) => Promise<T>;
  get: <T = any>(path: string, params?: RequestMessage, headers?: any) => Promise<T>;
  patch: <T = any>(
    path: string,
    msg?: RequestMessage,
    params?: RequestMessage,
    headers?: any,
  ) => Promise<T>;
  delete: <T = any>(
    path: string,
    msg?: RequestMessage,
    params?: RequestMessage,
    headers?: any,
  ) => Promise<T>;
  setToken: (token?: string, refreshToken?: string) => void;
  getToken: () => string;
};

export type User = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  mobile: string;
  image?: string;
  disable: boolean;
  type: number;
};
const ApplicationContext = React.createContext<{
  connection?: ConnectionApi;
  user?: User;
  events: events.EventEmitter;
}>({} as any);
const universalCookie = new UniversalCookie();
export const ApplicationProvider: FC<{ children: React.ReactNode }> = (props) => {
  const [token, setToken] = useState<string>(universalCookie.get(AUTH_TOKEN));
  const history = useNavigate();
  const evts = useRef(new events.EventEmitter()).current;

  const getUserInfo = useCallback((token?: string) => {
    if (token) {
      try {
        const user: any = jwtDecode(token);
        return {
          id: user.id,
          firstName: user.name,
          lastName: user.lastName,
          email: user.email,
          mobile: user.email,
          image: user.image,
          disable: user.disable,
          type: user.type,
        };
      } catch (e) {
        return undefined;
      }
    } else {
      return undefined;
    }
  }, []);

  const [user, setUser] = useState<User | undefined>(getUserInfo(token));

  // useEffect(() => {
  //   const token = universalCookie.get(AUTH_TOKEN);
  //   if (token) {
  //     const decodedJwt = parseJwt(token);
  //
  //     if (decodedJwt.exp * 1000 < Date.now()) {
  //       console.log('logout');
  //     }
  //   }
  // }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      const newToken = universalCookie.get(AUTH_TOKEN);
      if (token !== newToken) {
        setToken(newToken);
        setUser(getUserInfo(newToken));
      }
    }, 500);
    return () => {
      clearInterval(timer);
    };
  }, [getUserInfo, history, token]);

  const sendRequest = useCallback(
    async (
      method: (url: string) => superagent.SuperAgentRequest,
      path: string,
      msg?: RequestMessage,
      query?: RequestMessage,
      headers?: RequestHeader,
    ) => {
      if (tokenChanging) {
        await new Promise((resolve, reject) => {
          requestQue.push({ promiseResolve: resolve, promiseReject: reject });
        }).catch(() => {
          throw Error('Invalid Token');
        });
      } else {
        tokenChanging = true;
      }

      return new Promise((resolve, reject) => {
        const url = `${config.api.baseUrl}`;
        changeAccessToken(url).then(() => {
          const req = method(`${url}/${path}`).set('Content-Type', 'application/json');
          if (query) {
            req.query(query);
          }
          if (token !== undefined && token !== null) {
            req.set('Authorization', `Bearer ${token}`);
          }
          if (headers) {
            Object.keys(headers).forEach((key) => req.set(key, headers[key]));
          }

          let jsonString = '';
          try {
            jsonString = JSON.stringify(msg);
          } catch (e) {
            throw Error('Invalid JSON object');
          }

          req
            .send(jsonString)
            .then((res: any) => {
              resolve(res.body);
            })
            .catch((res: any) => {
              if (res.response) {
                const { response } = res;
                if (response.statusCode) {
                  const { status, statusText } = response;
                  if (res.response.body?.message) {
                    reject({
                      status,
                      statusText: statusText ?? `${status}`,
                      message: res.response.body?.message,
                      body: res.response.body,
                    });
                  } else {
                    reject({
                      status,
                      statusText: statusText ?? `${status}`,
                      message: statusText ? statusText : 'Unknown',
                      body: res.response.body,
                    });
                  }
                } else {
                  reject({
                    statusCode: 500,
                    statusText: 'Unknown',
                    code: 'Unknown',
                    message: 'Unknown',
                  });
                }
              }
            });
        });
      }) as any;
    },
    [token],
  );

  const changeAccessToken = (url: string) => {
    return new Promise((resolve, reject) => {
      const accessToken = universalCookie.get(AUTH_TOKEN);
      const accessTokenDecode: any = accessToken ? jwtDecode(accessToken) : accessToken;
      const refreshToken = universalCookie.get(REFRESH_TOKEN);
      if (refreshToken && new Date((accessTokenDecode?.exp + 300) * 1000) >= new Date()) {
        if (
          !accessTokenDecode ||
          new Date(accessTokenDecode?.exp * 1000 - 5 * 60 * 1000) <= new Date()
        ) {
          const req1 = superagent
            .post(`${url}/users/refresh-session`)
            .set('Content-Type', 'application/json');
          req1
            .send({ token: refreshToken, accessToken: accessToken })
            .then(async (res) => {
              const token: { token: string; refreshToken: string } = res.body.token;
              const decoded: any = jwtDecode(token.token);
              const options: CookieSetOptions = {
                path: '/',
                domain: '',
                expires: new Date((decoded.exp + 360 * 36000 * 24) * 1000),
              };
              const options1: CookieSetOptions = {
                ...options,
                expires: new Date((decoded.exp + 360 * 36000 * 24) * 1000),
              };
              universalCookie.set(AUTH_TOKEN, token.token, options);
              universalCookie.set(REFRESH_TOKEN, token.refreshToken, options1);
              setToken(token.token);
              const requestQue1 = [...requestQue];
              tokenChanging = false;
              requestQue = [];
              for (const requestQue1Element of requestQue1) {
                await requestQue1Element.promiseResolve();
              }
              resolve('');
            })
            .catch(async (err) => {
              if (err.statusCode === 403 || err.statusCode === 406) {
                universalCookie.remove(AUTH_TOKEN, { path: '/', domain: '' });
                universalCookie.remove(REFRESH_TOKEN, {
                  path: '/',
                  domain: '',
                });
              }
              const requestQue1 = [...requestQue];
              tokenChanging = false;
              requestQue = [];
              for (const requestQue1Element of requestQue1) {
                await requestQue1Element.promiseReject();
              }

              reject('');
              //
            });
        } else {
          const requestQue1 = [...requestQue];
          tokenChanging = false;
          requestQue = [];
          for (const requestQue1Element of requestQue1) {
            requestQue1Element.promiseResolve();
          }
          resolve('');
        }
      } else {
        universalCookie.remove(AUTH_TOKEN, { path: '/', domain: '' });
        universalCookie.remove(REFRESH_TOKEN, { path: '/', domain: '' });
        const requestQue1 = [...requestQue];
        tokenChanging = false;
        requestQue = [];
        for (const requestQue1Element of requestQue1) {
          requestQue1Element.promiseResolve();
        }
        resolve('');
      }
    });
  };

  const post = useCallback(
    (path: string, msg?: RequestMessage, query?: RequestMessage, headers?: RequestHeader) => {
      return sendRequest(superagent.post, path, msg, query, headers);
    },
    [sendRequest],
  );
  const put = useCallback(
    (path: string, msg?: RequestMessage, query?: RequestMessage, headers?: RequestHeader) => {
      return sendRequest(superagent.put, path, msg, query, headers);
    },
    [sendRequest],
  );
  const get = useCallback(
    (path: string, query?: RequestMessage, headers?: RequestHeader) => {
      return sendRequest(superagent.get, path, undefined, query, headers);
    },
    [sendRequest],
  );
  const patch = useCallback(
    (path: string, msg?: RequestMessage, query?: RequestMessage, headers?: RequestHeader) => {
      return sendRequest(superagent.patch, path, msg, query, headers);
    },
    [sendRequest],
  );
  const del = useCallback(
    (path: string, msg?: RequestMessage, query?: RequestMessage, headers?: RequestHeader) => {
      return sendRequest(superagent.delete, path, msg, query, headers);
    },
    [sendRequest],
  );

  const send = useCallback(
    (api: ApiType, path: string, msg?: RequestMessage, params?: RequestMessage, headers?: any) => {
      switch (api) {
        case 'post':
          return post(path, msg, params, headers);
        case 'get':
          return get(path, params ?? msg, headers);
        case 'put':
          return put(path, msg, params, headers);
        case 'patch':
          return patch(path, msg, params, headers);
        case 'delete':
          return del(path, msg, params, headers);
      }
    },
    [del, get, post, put, patch],
  );

  const updateToken = useCallback(
    (token?: string, refreshToken?: string) => {
      if (token && refreshToken) {
        try {
          const decoded: any = jwtDecode(token);
          // const refreshTokenDecoded: any = jwtDecode(refreshToken);
          const options: CookieSetOptions = { path: '/', domain: '' };
          const options1: CookieSetOptions = { ...options };
          options1.expires = new Date((decoded.exp + 360 * 36000 * 24) * 1000);
          options.expires = new Date((decoded.exp + 360 * 36000 * 24) * 1000);
          universalCookie.set(AUTH_TOKEN, token, options);
          universalCookie.set(REFRESH_TOKEN, refreshToken, options1);
          setToken(token);
          setUser(getUserInfo(token));
        } catch (e) {
          console.error(e);
        }
      } else if (token) {
        try {
          const decoded: any = jwtDecode(token);
          const options: CookieSetOptions = { path: '/', domain: '' };
          options.expires = new Date(decoded.exp * 1000);
          universalCookie.set(AUTH_TOKEN, token, options);
          setToken(token);
          setUser(getUserInfo(token));
        } catch (e) {
          console.error(e);
        }
      } else {
        universalCookie.remove(AUTH_TOKEN, { path: '/', domain: '' });
        universalCookie.remove(REFRESH_TOKEN, { path: '/', domain: '' });
      }
    },
    [getUserInfo],
  );
  const getToken = useCallback(() => {
    if (token) {
      return token;
    } else {
      return '';
    }
  }, [token]);
  const connection: ConnectionApi = useMemo(
    () => ({ send, post, put, get, patch, delete: del, setToken: updateToken, getToken }),
    [send, post, put, get, patch, del, updateToken, getToken],
  );
  return (
    <CookiesProvider>
      <ApplicationContext.Provider
        value={{
          connection,
          user,
          events: evts,
        }}>
        {props.children}
      </ApplicationContext.Provider>
    </CookiesProvider>
  );
};

export default ApplicationContext;
