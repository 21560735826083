import React, { forwardRef, ForwardRefRenderFunction } from 'react';
// @mui material components
import MenuItem from '@mui/material/MenuItem';
import Link from '@mui/material/Link';

// custom styles for the NotificationItem
import menuItem from './styles';
import { Box, Typography } from '@mui/material';

// Typechecking props for the NotificationItem
interface NotificationItemProps {
  icon: any;
  title: string;
  [x: string]: any;
}

const NotificationItem: ForwardRefRenderFunction<any, NotificationItemProps> = (
  { icon, title, ...rest },
  ref,
) => (
  <MenuItem {...rest} ref={ref} sx={(theme) => menuItem(theme)}>
    <Box component={Link} py={0.5} display='flex' alignItems='center' lineHeight={1}>
      <Typography variant='body1' color='secondary' lineHeight={0.75}>
        {icon}
      </Typography>
      <Typography variant='button' fontWeight='regular' sx={{ ml: 1 }}>
        {title}
      </Typography>
    </Box>
  </MenuItem>
);

export default forwardRef<any, NotificationItemProps>(NotificationItem);
