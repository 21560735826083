import React, { FC, useEffect, useRef, useState } from 'react';
import Tabs, { tabsClasses } from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useConnection } from '../../connection/Application';
import Titles from '../Titles/Titles';
import { Grid } from '@mui/material';
import { useQueryParam } from 'use-query-params';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

const getWindowSize = () => {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
};

const Grade: FC = () => {
  const [category] = useQueryParam<string | undefined>('category');
  const [grade, setGrade] = useQueryParam<string | undefined>('grade');
  const [value, setValue] = useState<number>(0);
  const [oldCategory, setOldCategory] = useState<string | undefined>();
  const mainTitles = useRef<
    {
      description: string;
      id: string;
      subTitle: {
        description: string;
        id: string;
        title: string;
        orderNum: number;
      }[];
      title: string;
      url?: string;
      pdf?: string;
    }[]
  >([]);
  const [grades, setGrades] = useState<
    {
      grade: string;
      id: string;
      color: string;
      mainTitles: {
        description: string;
        id: string;
        subTitle: {
          description: string;
          id: string;
          title: string;
          orderNum: number;
        }[];
        title: string;
      }[];
    }[]
  >([]);
  const [colors] = useState([
    '#F43545',
    '#FF8901',
    '#FAD717',
    '#00BA71',
    '#00C2DE',
    '#00418D',
    '#5F2879',
  ]);
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const connection = useConnection();

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize(getWindowSize());
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const category1 = await connection.get(`category/${category}/fn`);
      setGrades(
        category1.response.body.grades.map((e: any, i: number) => ({
          grade: e.title,
          id: e.id,
          mainTitles: e.mainTitle,
          color: colors[i % 7],
        })),
      );
      if (!grade) {
        setGrade(category1.response.body.grades[0]?.id);
        setValue(0);
        mainTitles.current = category1.response.body.grades[0]?.mainTitle ?? [];
      } else {
        const x = category1.response.body.grades.findIndex((y: any) => y.id === grade);
        if (oldCategory !== undefined && oldCategory !== category) {
          setGrade(category1.response.body.grades?.[0]?.id);
          setValue(0);
          mainTitles.current = category1.response.body.grades[0]?.mainTitle ?? [];
        } else {
          if (x === -1) {
            setGrade(category1.response.body.grades?.[0]?.id);
            setValue(0);
            mainTitles.current = category1.response.body.grades[0]?.mainTitle ?? [];
          } else {
            setValue(x);
            mainTitles.current = category1.response.body.grades[x]?.mainTitle ?? [];
          }
        }
        setOldCategory(category);
      }
    };
    fetchData();
  }, [colors, category, connection]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    setGrade(grades[newValue].id);
    mainTitles.current = grades[newValue].mainTitles;
  };

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Grid style={{ display: 'flex', padding: 10 }}>
      <Tabs
        sx={{
          width: matches ? 90 : 150,
          height: '73vh',
          '& .MuiTabs-flexContainer': {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
          },
          [`& .${tabsClasses.scrollButtons}`]: {
            '&.Mui-disabled': { opacity: 0.3 },
          },
        }}
        variant='scrollable'
        scrollButtons={(windowSize.innerHeight * 73) / 100 / 70 < grades.length}
        orientation='vertical'
        value={value}
        onChange={handleChange}
        style={{ width: matches ? '90px' : '150px' }}
        aria-label='icon label tabs example'>
        {grades.map((e, i) => (
          <Tab
            key={e.id}
            label={e.grade}
            sx={{
              '&.MuiButtonBase-root': {
                minWidth: matches ? '65px' : '90px',
                padding: matches ? '12px 4px 12px 4px' : '12px 16px 12px 16px',
              },
            }}
            style={{
              boxShadow:
                'rgb(0 0 0 / 25%) 0px 54px 55px, rgb(0 0 0 / 12%) 0px -12px 30px, rgb(0 0 0 / 12%) 0px 4px 6px, rgb(0 0 0 / 17%) 0px 12px 13px, rgb(0 0 0 / 9%) 0px -3px 5px',
              color: i === value ? 'white' : e.color,
              backgroundColor: i === value ? e.color : 'white',
              width: matches ? 50 : 70,
              height: matches ? 50 : 70,
              borderRadius: '60% 0 0 60%',
            }}
          />
        ))}
      </Tabs>
      <Titles mainTitles={mainTitles.current} />
    </Grid>
  );
};

export default Grade;
