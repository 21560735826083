import React, { FC, useEffect, useState } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import { WebUx } from './layouts/WebUx';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import SignUp from './pages/SignUp';
import Home from './pages/Home';
import RequestPasswordRest from './pages/RequestPasswordRest';
import Login from './pages/Login';
import RestPassword from './pages/RestPassword';
import { CssBaseline } from '@mui/material';
import QPage from './pages/Qpage';

const ScrollToTop: FC = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    if (window) window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const App: FC = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(true);
  return (
    <div className='App'>
      <CssBaseline />
      <ScrollToTop />
      <QueryParamProvider adapter={ReactRouter6Adapter}>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/login' element={<Login setIsAuthenticated={setIsAuthenticated} />} />
          <Route path='/signup' element={<SignUp />} />
          <Route path='/forget-password' element={<RequestPasswordRest />} />
          <Route path='/password-reset' element={<RestPassword />} />
          <Route path='/app' element={<WebUx />} />
          <Route path='/question/:subTitleId' element={<QPage />} />
          {isAuthenticated ? (
            <Route path='*' element={<Navigate to='/' />} />
          ) : (
            <Route path='*' element={<Navigate to='/login' />} />
          )}
        </Routes>
      </QueryParamProvider>
      <p id='text101' style={{ fontSize: '16px', width: 'fit-content' }}></p>
    </div>
  );
};
export default App;
