import React, { FC } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useUserInfo } from '../connection/Application';
import { Grid } from '@mui/material';

const GuardedRoute: FC<any> = ({
  component: Component,
  authRoles,
  userRoles,
  redirectPath,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(props: any) =>
        authRoles?.filter((e: string) => userRoles?.findIndex((e1: string) => e1 === e) !== -1)
          .length !== 0 ? (
          <Component {...props} />
        ) : (
          <Navigate to={redirectPath} replace={true} />
        )
      }
    />
  );
};

export const WebUxContent: FC = () => {
  const user = useUserInfo();
  return (
    <Grid container spacing={2}>
      <Routes></Routes>
    </Grid>
  );
};
