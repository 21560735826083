import React, { FC, useCallback, useEffect, useState } from 'react';
import { useConnection, useUserInfo } from '../connection/Application';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import {
  Avatar,
  Button,
  Container, FormControl, FormHelperText,
  Grid, IconButton,
  InputAdornment,
  InputLabel,
  Link,
  OutlinedInput,
  TextField,
  Typography,
} from '@mui/material';
import Header from '../components/Header/header';
import Footer from '../components/Footer/Footer';
import { makeStyles } from '@mui/styles';
import { useQueryParam } from 'use-query-params';
import { Visibility, VisibilityOff } from '@mui/icons-material';

const useStyles = makeStyles((theme: any) => ({
  paper: {
    paddingTop: '5vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const RestPassword: FC = () => {
  const classes = useStyles();
  const [email] = useQueryParam<string | undefined>('email');
  const connection = useConnection();
  const navigator = useNavigate();
  const user = useUserInfo();
  const [loading, setLoading] = useState<boolean>(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const [showPassword1, setShowPassword1] = React.useState(false);
  const [userData, setUserData] = useState<{
    verificationCode: string;
    password: string;
    confirmPassword: string;
  }>({
    verificationCode: '',
    password: '',
    confirmPassword: '',
  });

  const [error, setError] = useState<{
    verificationCode: string;
    password: string;
    confirmPassword: string;
  }>({
    verificationCode: '',
    password: '',
    confirmPassword: '',
  });

  useEffect(() => {
    if (user) {
      navigator('/');
    }
  }, [user]);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleClickShowPassword1 = () => {
    setShowPassword1(!showPassword1);
  };

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };


  const login = useCallback(() => {
    setLoading(true);
    const error1 = {
      verificationCode: '',
      password: '',
      confirmPassword: '',
    };
    if (!userData.verificationCode) {
      error1.verificationCode = 'verificationCode is required';
      // eslint-disable-next-line
    }
    if (userData.password === '') {
      error1.password = 'Password is required';
    } else if (
      !/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/.test(userData.password)
    ) {
      error1.password = 'Password is not valid';
    }
    if (userData.confirmPassword === '' || userData.password !== userData.confirmPassword) {
      error1.confirmPassword = 'Confirm Password not match';
    }
    setError(error1);
    if (error1.verificationCode === '' && error1.password === '') {
      connection
        .patch('users/user/password-reset', {
          email,
          verificationCode: userData.verificationCode,
          password: userData.password,
        })
        .then(async (response: any) => {
          await Swal.fire({
            title: 'Success!',
            text: 'Successfully Reset your Password.',
            icon: 'success',
            confirmButtonText: 'Ok',
          });
          navigator('/');
        })
        .catch(() => {
          Swal.fire({
            title: 'Error!',
            text: 'Reset Password failed. Invalid Verification Code or Email. Please Try Again Later',
            icon: 'error',
            confirmButtonText: 'Ok',
          });
        })
        .finally(() => setLoading(false));
    } else {
      setLoading(false);
    }
  }, [userData]);

  return (
    <>
      <Container component='main' maxWidth='xs' sx={{ minHeight: 'calc( 100vh - 128px)' }}>
        {/* <CssBaseline />*/}
        <Header />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}></Avatar>
          <Typography component='h1' variant='h5'>
            Reset Your Password
          </Typography>
          <Typography>
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            We want to make sure it's really you. In order to further verify your identity, enter
            the verification code that was sent to {email ?? 'email'}
          </Typography>
          <div className={classes.form}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  type='number'
                  variant='outlined'
                  required
                  fullWidth
                  value={userData.verificationCode}
                  helperText={error.verificationCode}
                  onChange={(e) => setUserData({ ...userData, verificationCode: e.target.value })}
                  id='verificationCode'
                  label='verificationCode'
                  name='Verification Code'
                  autoComplete='Verification Code'
                  FormHelperTextProps={{ style: { color: '#E53935' } }}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl style={{ width: '100%' }}>
                  <InputLabel htmlFor="password">Password</InputLabel>
                  <OutlinedInput
                    required
                    fullWidth
                    value={userData.password}
                    onChange={(e) => setUserData({ ...userData, password: e.target.value })}
                    name='password'
                    label='Password'
                    id='password'
                    autoComplete='password'
                    type={showPassword ? 'text' : 'password'}
                    color={'primary'}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end">
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  {!!error.password && (
                    <FormHelperText error id="password">
                      {error.password}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl style={{ width: '100%' }}>
                  <InputLabel htmlFor="confirmPassword">Confirm Password</InputLabel>
                  <OutlinedInput
                    required
                    fullWidth
                    value={userData.confirmPassword}
                    onChange={(e) => setUserData({ ...userData, confirmPassword: e.target.value })}
                    name='confirmPassword'
                    label='Confirm Password'
                    id='confirmPassword'
                    autoComplete='password'
                    type={showPassword1 ? 'text' : 'password'}
                    color={'primary'}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword1}
                          onMouseDown={handleMouseDownPassword}
                          edge="end">
                          {showPassword1 ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  {!!error.confirmPassword && (
                    <FormHelperText error id="confirmPassword">
                      {error.confirmPassword}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              {/* <Grid item xs={12}>*/}
              {/*  <FormControlLabel*/}
              {/*    control={<Checkbox value='allowExtraEmails' color='primary' />}*/}
              {/*    label='I want to receive inspiration, marketing promotions and updates via email.'*/}
              {/*  />*/}
              {/* </Grid>*/}
            </Grid>
            <Button
              type='submit'
              fullWidth
              variant='contained'
              color='primary'
              onClick={() => login()}
              disabled={loading}
              sx={{ marginTop: 3, marginBottom: 3 }}
              className={classes.submit}>
              Reset Your Password
            </Button>
            <Grid>
              <Grid item>
                <Link href='/login' variant='body2'>
                  {/* eslint-disable-next-line react/no-unescaped-entities */}
                  Login
                </Link>
              </Grid>
              <Grid item>
                <Link href='/signup' variant='body2'>
                  {/* eslint-disable-next-line react/no-unescaped-entities */}
                  Don't have an account yet? Sign Up
                </Link>
              </Grid>
            </Grid>
          </div>
        </div>
      </Container>
      <Footer />
    </>
  );
};

export default RestPassword;
